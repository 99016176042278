/**
  * Usage: dateString | localDate:'format'
 **/

import { Pipe, PipeTransform, inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { SessionService } from './session.service';

@Pipe({
    name: 'localDate',
    standalone: true
})
export class LocalDatePipe implements PipeTransform {
	private session = inject(SessionService);


	transform(value: any, format?: string): string {

		if (!value) { return ''; }
		if (!format) { format = 'dd.MM.yyyy hh:mm:ss'; }

		return formatDate(value, format, this.session.locale);
	}
}
