import { TradingPairAlert } from 'app/api/models/trading-pair-alert';

const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm'

export const tradingPairBrokers: TradingPairAlert[] = [
    {
        id: '1',
        name: 'FETUSDT Crossing 0.20',
        type: 'PRICE_COUNTER',
        action: 'CROSSING', //'CROSSING' | 'MOVING_UP' | 'MOVING_DOWN',
        timeInterval: '4h', //'4h' | '6h' | '12h' | '1D' | '3D' | '1W',
        exchangeName: 'FTX',
        tradingPairName: 'FETUSDT',
        conditionValue: 3.5,
        // message: string; TODO not needed since the message can be generated
        trigger: 'ONCE',//'ONCE' | 'EVERY_TIME',
        expiresOn: '2023-09-14 14:55',
        // notifyViaPopUp?: boolean;
        status: 'OPEN',//'OPEN' | 'TRIGGERED' | 'CLOSED',
        //meta
        createdAt: '2023-04-14 14:55',
        closedAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55'
    },
    {
        id: '2',
        name: 'FETUSDT Crossing 0.20',
        type: 'PRICE_VALUE', // | 'PRICE_PERCENT' | 'RSI_VALUE' | 'RSI_PERCENT' | 'VOLUME_PERCENT' | 'EXCHANGE_SPREAD',
        action: 'MOVING_UP', //'CROSSING' | 'MOVING_UP' | 'MOVING_DOWN',
        timeInterval: '6h', //'4h' | '6h' | '12h' | '1D' | '3D' | '1W',
        exchangeName: 'Binance',
        tradingPairName: 'BTCUSDT',
        conditionValue: 3.5,
        // message: string; TODO not needed since the message can be generated
        trigger: 'ONCE',//'ONCE' | 'EVERY_TIME',
        expiresOn: '2023-09-14 14:55',
        // notifyViaPopUp?: boolean;
        status: 'OPEN',//'OPEN' | 'TRIGGERED' | 'CLOSED',
        //meta
        createdAt: '2023-04-14 14:55',
        closedAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55'
    },
    {
        id: '3',
        name: 'FETUSDT Crossing 0.20',
        type: 'PRICE_PERCENT', // | 'RSI_VALUE' | 'RSI_PERCENT' | 'VOLUME_PERCENT' | 'EXCHANGE_SPREAD',
        action: 'MOVING_DOWN', //'CROSSING' | 'MOVING_UP' | 'MOVING_DOWN',
        timeInterval: '12h', //'4h' | '6h' | '12h' | '1D' | '3D' | '1W',
        exchangeName: 'Huobi',
        conditionValue: 3.5,
        // message: string; TODO not needed since the message can be generated
        trigger: 'ONCE',//'ONCE' | 'EVERY_TIME',
        expiresOn: '2023-09-14 14:55',
        // notifyViaPopUp?: boolean;
        status: 'OPEN',//'OPEN' | 'TRIGGERED' | 'CLOSED',
        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55'
    },
    {
        id: '4',
        name: 'FETUSDT Crossing 0.20',
        type: 'RSI_VALUE', // | 'RSI_VALUE' | 'RSI_PERCENT' | 'VOLUME_PERCENT' | 'EXCHANGE_SPREAD',
        action: 'CROSSING', //'CROSSING' | 'MOVING_UP' | 'MOVING_DOWN',
        timeInterval: '1D', //'4h' | '6h' | '12h' | '1D' | '3D' | '1W',
        exchangeName: 'Coinbase',
        tradingPairName: 'SPELLUSDT',
        conditionValue: 3.5,
        // message: string; TODO not needed since the message can be generated
        trigger: 'EVERY_TIME',//'ONCE' | 'EVERY_TIME',
        expiresOn: '2023-09-14 14:55',
        // notifyViaPopUp?: boolean;
        status: 'TRIGGERED',//'OPEN' | 'TRIGGERED' | 'CLOSED',
        //meta
        createdAt: '2023-04-14 14:55',
        closedAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55'
    },
    {
        id: '5',
        name: 'FETUSDT Crossing 0.20',
        type: 'RSI_PERCENT', //| 'VOLUME_PERCENT' | 'EXCHANGE_SPREAD',
        action: 'CROSSING', //'CROSSING' | 'MOVING_UP' | 'MOVING_DOWN',
        timeInterval: '3D', //'4h' | '6h' | '12h' | '1D' | '3D' | '1W',
        exchangeName: 'Kraken',
        tradingPairName: 'LTCUSDT',
        conditionValue: 3.5,
        // message: string; TODO not needed since the message can be generated
        trigger: 'EVERY_TIME',//'ONCE' | 'EVERY_TIME',
        expiresOn: '2023-09-14 14:55',
        // notifyViaPopUp?: boolean;
        status: 'CLOSED',//'OPEN' | 'TRIGGERED' | 'CLOSED',
        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55'
    },
    {
        id: '6',
        name: 'FETUSDT Crossing 0.20',
        type: 'VOLUME_PERCENT', // | 'EXCHANGE_SPREAD',
        action: 'CROSSING', //'CROSSING' | 'MOVING_UP' | 'MOVING_DOWN',
        timeInterval: '1W', //'4h' | '6h' | '12h' | '1D' | '3D' | '1W',
        tradingPairName: 'ETHUSDT',
        conditionValue: 3.5,
        // message: string; TODO not needed since the message can be generated
        trigger: 'ONCE',//'ONCE' | 'EVERY_TIME',
        expiresOn: '2023-09-14 14:55',
        // notifyViaPopUp?: boolean;
        status: 'OPEN',//'OPEN' | 'TRIGGERED' | 'CLOSED',
        //meta
        createdAt: '2023-04-14 14:55',
        closedAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55'
    },
    {
        id: '7',
        name: 'FETUSDT Crossing 0.20',
        type: 'EXCHANGE_SPREAD', // 
        action: 'CROSSING', //'CROSSING' | 'MOVING_UP' | 'MOVING_DOWN',
        timeInterval: '4h', //'4h' | '6h' | '12h' | '1D' | '3D' | '1W',
        exchangeName: 'BitFinex',
        tradingPairName: 'JOEUSDT',
        conditionValue: 3.5,
        // message: string; TODO not needed since the message can be generated
        trigger: 'ONCE',//'ONCE' | 'EVERY_TIME',
        expiresOn: '2023-09-14 14:55',
        // notifyViaPopUp?: boolean;
        status: 'OPEN',//'OPEN' | 'TRIGGERED' | 'CLOSED',
        //meta
        createdAt: '2023-04-14 14:55',
        closedAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55'
    }
];
