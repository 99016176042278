import { OnInit, Component, inject } from '@angular/core';
import { VersionCheckService } from './core/utils/version-check.service';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent implements OnInit
{
    private _versionCheckService: VersionCheckService = inject(VersionCheckService);

    ngOnInit(): void
    {
        this._versionCheckService.checkForUpdates();
    }
}
