import { Injectable } from '@angular/core';
import { MockApiBase } from '@fuse/mock-api/mock-api.base';
import { FilterModel } from 'app/api/data-event-model';
import { tradingPairBrokers as tradingPairBrokersData } from './data';

@Injectable({
    providedIn: 'root',
})
export class TradingPairAlertMockApi extends MockApiBase {
    /**
     * Constructor
     */
    constructor() {
        // Register Mock API handlers
        super(
            tradingPairBrokersData,
            'v1/trading-pair-alerts',
            'v1/trading-pair-alerts/{id}'
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getCustomFilterModel(
        key: string,
        value: any,
        _filterModelMap: Map<string, FilterModel>
    ): FilterModel {
        switch (key) {
            case 'tradingPairId': {
                return {
                    name: 'tradingPair.id',
                    filterSearchType: 'equals',
                    value: value,
                };
            }
            case 'exchangeId': {
                return {
                    name: 'exchange.id',
                    filterSearchType: 'equals',
                    value: value,
                };
            }
        }

        return null;
    }
}
