import { PortfolioTradingPattern } from 'app/api/models/portfolio-trading-pattern';


export const tradingPatterns: PortfolioTradingPattern[] = [
    {
        id: '1',
        //portfolio
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        timeFramesEnabled: false,

        //long
        longSetupType: true,
        longRiskType: true,
        longMistakeType: true,
        //short
        shortSetupType: true,
        shortRiskType: true,
        shortMistakeType: true,

        name: 'FUNDEMENTAL',
        description: 'Entered trade based on fundemental research'
    },
    {
        id: '2',
        //portfolio
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        timeFramesEnabled: false,

        //long
        longSetupType: true,
        longRiskType: false,
        longMistakeType: false,
        //short
        shortSetupType: false,
        shortRiskType: true,
        shortMistakeType: true,

        name: 'POSITIVE NEWS',
        description: 'Entered trade based on positive news'
    },
    {
        id: '3',
        //portfolio
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        timeFramesEnabled: false,

        //long
        longSetupType: false,
        longRiskType: true,
        longMistakeType: true,
        //short
        shortSetupType: true,
        shortRiskType: false,
        shortMistakeType: false,

        name: 'NEGATIVE NEWS',
        description: 'Entered trade based on negative news'
    },
    {
        id: '4',
        //portfolio
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        timeFramesEnabled: true,

        //long
        longSetupType: true,
        longRiskType: false,
        longMistakeType: false,
        //short
        shortSetupType: false,
        shortRiskType: true,
        shortMistakeType: true,

        name: 'HEIKIN ASHI - BUY',
        description: 'HeikinAshi - change from RED to GREEN'
    },
    {
        id: '8',
        //portfolio
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        timeFramesEnabled: true,
        //long
        longSetupType: false,
        longRiskType: true,
        longMistakeType: true,
        //short
        shortSetupType: true,
        shortRiskType: false,
        shortMistakeType: false,

        name: 'HEIKIN ASHI - SELL',
        description: 'HeikinAshi - change from GREEN to RED'
    },
    {
        id: '12',
        //portfolio
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        timeFramesEnabled: true, 

        //long
        longSetupType: true,
        longRiskType: false,
        longMistakeType: false,
        //short
        shortSetupType: false,
        shortRiskType: true,
        shortMistakeType: true,

        name: 'STOCH RSI - BUY',
        description: 'Stoch RSI - change from GREEN to RED',
        createdAt: '',
        createdBy: '',
        lastModifiedAt: '',
        lastModifiedBy: ''
    }
];
