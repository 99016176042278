<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.default"
  [opened]="!isScreenSmall"
  >
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center justify-start h-20 p-6 pb-0">
      <!-- Light version -->
      <img
        class="dark:hidden w-30"
        src="assets/images/logo/logo-text.svg"
        alt="Logo image"
        >
        <!-- Dark version -->
        <img
          class="hidden dark:flex w-32"
          src="assets/images/logo/logo-text-on-dark.svg"
          alt="Logo image"
          >
        </div>
      </ng-container>
    </fuse-vertical-navigation>

    <!-- Wrapper -->
    <div class="flex flex-col flex-auto w-full min-w-0">

      <!-- Header -->
      <div
        class="relative flex flex-0 items-center w-full h-14 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
          mat-icon-button
          (click)="toggleNavigation('mainNavigation')"
          >
          <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
          <languages></languages>
          <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
          <messages></messages>
          <settings></settings>
          <notifications></notifications>
          <!-- <button
          class="lg:hidden"
          mat-icon-button
          (click)="quickChat.toggle()">
          <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
        </button> -->
        <user></user>
      </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
      Otherwise, layout changes won't be registered and the view won't be updated! -->
      @if (true) {
        <router-outlet></router-outlet>
      }
    </div>

    <!-- Footer -->
    <div
      class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
      <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>

  </div>

  <!-- Quick chat -->
  <!-- <quick-chat #quickChat="quickChat"></quick-chat> -->