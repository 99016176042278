import { Injectable } from '@angular/core';
import { MockApiBase } from '@fuse/mock-api/mock-api.base';
import { portfolio as portfoliosData } from './data';

@Injectable({
    providedIn: 'root',
})
export class PortfolioMockApi extends MockApiBase {
    /**
     * Constructor
     */
    constructor() {
        // Register Mock API handlers
        super(portfoliosData, 'v1/portfolios', 'v1/portfolios/{id}');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
}
