import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { tap } from 'rxjs/operators';
import { Navigation } from 'app/core/navigation/navigation.types';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { compactNavigation, defaultNavigation, horizontalNavigation } from '../../app.navigation.config';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _httpClient = inject(HttpClient);

    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {
        // Fill compact navigation children using the default navigation
        this._compactNavigation.forEach((compactNavItem) =>
        {
            this._defaultNavigation.forEach((defaultNavItem) =>
            {
                if (defaultNavItem.id === compactNavItem.id)
                {
                    compactNavItem.children = cloneDeep(defaultNavItem.children);
                }
            });
        });

        // Fill horizontal navigation children using the default navigation
        this._horizontalNavigation.forEach((horizontalNavItem) =>
        {
            this._defaultNavigation.forEach((defaultNavItem) =>
            {
                if (defaultNavItem.id === horizontalNavItem.id)
                {
                    horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                }
            });
        });

        // Return the response
        return of(
            {
                compact: cloneDeep(this._compactNavigation),
                default: cloneDeep(this._defaultNavigation),
                horizontal: cloneDeep(this._horizontalNavigation)
            }).pipe(
                tap((navigation) =>
                {
                    this._navigation.next(navigation);
                })
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
}
