import { Injectable, inject } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MockApiBase } from '@fuse/mock-api/mock-api.base';
import { ReportOverviewChart } from 'app/api/models/report-overview-chart';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root',
})
export class ReportOverviewChartMockApi extends MockApiBase {
    private _dateAdapter: DateAdapter<any> = inject(DateAdapter);

    /**
     * Constructor
     */
    constructor() {
        // Register Mock API handlers
        super([], 'v1/report/overview-chart', null);

        //fill data
        const _data = [];
        let _date = DateTime.fromJSDate(new Date(2022, 11, 1));
        for (let i = 0; i < 200; i++) {
            _date = this._dateAdapter.addCalendarDays(_date, 1);

            _data.push({
                dateTime: this._dateAdapter.format(_date, 'yyyy-MM-dd'),
                value:
                    Math.floor(Math.random() * 2000) *
                    [-1, 1][Math.floor(Math.random() * 2)],
            });
        }

        const _item: ReportOverviewChart = {
            reportType: 'ACCOUNT_BALANCE',
            data: _data,
        };
        this._data = [_item];
    }
}
