import { TradingPairAlertLog } from 'app/api/models/trading-pair-alert-log';

const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm'

export const tradingPairBrokers: TradingPairAlertLog[] = [
    {
        id: '1',
        alert: {
            id: '1',
            name: 'FETUSDT Crossing 0.20',
            type: 'PRICE_COUNTER', //'PRICE_COUNTER' | 'PRICE_VALUE' | 'PRICE_PERCENT' | 'RSI_VALUE' | 'RSI_PERCENT' | 'VOLUME_PERCENT' | 'EXCHANGE_SPREAD',
            action: 'CROSSING', //'CROSSING' | 'MOVING_UP' | 'MOVING_DOWN',
            timeInterval: '4h', //'4h' | '6h' | '12h' | '1D' | '3D' | '1W',
            exchangeName: 'exchangeId',
            tradingPairName: 'tradingPairId',
            conditionValue: 3.5,
            // message: string; TODO not needed since the message can be generated
            trigger: 'ONCE',//'ONCE' | 'EVERY_TIME',
            expiresOn: '2023-09-14 14:55',
            // notifyViaPopUp?: boolean;
            status: 'OPEN',//'OPEN' | 'TRIGGERED' | 'CLOSED',
            //meta
            createdAt: '2023-04-14 14:55',
            closedAt: '2023-04-14 14:55',
            lastModifiedAt: '2023-05-14 14:55'
        },
        exchangeName: 'FTX',
        tradingPairId: '1',
        tradingPairName: 'TRXUSDT',

        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55'
    }
];
