<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
@if (layout === 'empty') {
  <empty-layout></empty-layout>
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Centered -->
@if (layout === 'centered') {
  <centered-layout></centered-layout>
}

<!-- Enterprise -->
@if (layout === 'enterprise') {
  <enterprise-layout></enterprise-layout>
}

<!-- Modern -->
@if (layout === 'modern') {
  <modern-layout></modern-layout>
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Classic -->
@if (layout === 'classic') {
  <classic-layout></classic-layout>
}

<!-- Classy -->
@if (layout === 'classy') {
  <classy-layout></classy-layout>
}

<!-- Compact -->
@if (layout === 'compact') {
  <compact-layout></compact-layout>
}

<!-- Dense -->
@if (layout === 'dense') {
  <dense-layout></dense-layout>
}

<!-- Thin -->
@if (layout === 'thin') {
  <thin-layout></thin-layout>
}