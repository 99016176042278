import { Asset } from 'app/api/models/asset';



export const assetData: Asset[] = [
    {
        id: '1',
        name: 'USD',
        shortName: '',
        symbol: '$',
        //
        active: true,
        createdAt: '',
        createdBy: '',
        lastModifiedAt: '',
        lastModifiedBy: ''
    },
    {
        id: '2',
        name: 'Euro',
        shortName: 'EUR',
        symbol: '€',
        //
        active: true,
        createdAt: '',
        createdBy: '',
        lastModifiedAt: '',
        lastModifiedBy: ''
    },
    {
        id: '3',
        name: 'Chinese Yuan',
        shortName: 'CNY',
        symbol: '¥',
        //
        active: true,
        createdAt: '',
        createdBy: '',
        lastModifiedAt: '',
        lastModifiedBy: ''
    },
    {
        id: '4',
        name: 'Russian ruble',
        shortName: 'RUB',
        symbol: 'RUB',
        //
        active: true
    },
    {
        id: '5',
        name: 'Brazilian real',
        shortName: 'BRL',
        symbol: 'BRL',
        //
        active: true
    },
    {
        id: '6',
        name: 'Fijian dollar',
        shortName: 'FJD',
        symbol: 'FJD',
        //
        active: true
    },
    {
        id: '7',
        name: 'Bulgarian lev',
        shortName: 'BRL',
        symbol: 'BRL',
        //
        active: true
    },
    {
        id: '8',
        name: 'Candaian dollar',
        shortName: 'CAD',
        symbol: 'CAD',
        //
        active: true
    },
    {
        id: '9',
        name: 'Chilean peso',
        shortName: 'CLP',
        symbol: 'CLP',
        //
        active: true
    },
    {
        id: '10',
        name: 'Egyptian pound',
        shortName: 'EGP',
        symbol: 'EGP',
        //
        active: true
    }, 
    {
        id: '11',
        name: 'Swiss Franc',
        shortName: 'CHF',
        symbol: 'CHF',
        //
        active: true
    }
];
