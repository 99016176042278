import { PortfolioTransaction } from 'app/api/models/portfolio-transaction';



export const portfolioTransactions: PortfolioTransaction[] = [
    {
        id: '1',
        portfolioId: '1',
        portfolioName: 'Portfolio 1',

        type: 'DEPOSIT',

        incomingAssetId: '1',
        incomingAssetName: 'USD',

        outgoingAssetId: null,
        outgoingAssetName: null,

        tradeId: null,
        tradeName: null,
        tradeSide: null,

        notes: 'Deposited Euros notes',

        incomingAssetAmount: 400,
        incomingAssetAmountInDefaultCurrency: 453,

        outgoingAssetAmount: null,
        outgoingAssetAmountInDefaultCurrency: null,

        completedAt: '2019-06-24T11:48:07.883' // date time 
    },
    {
        id: '2',
        portfolioId: '2',
        portfolioName: 'Portfolio 2',
        type: 'DEPOSIT',

        incomingAssetId: '2',
        incomingAssetName: 'Euro',

        outgoingAssetId: null,
        outgoingAssetName: null,

        tradeId: null,
        tradeName: null,
        tradeSide: null,

        notes: 'Deposited Euros',

        incomingAssetAmount: 400,
        incomingAssetAmountInDefaultCurrency: 453,

        outgoingAssetAmount: null,
        outgoingAssetAmountInDefaultCurrency: null,

        completedAt: '2019-06-24T11:48:07.883' // date time 
    },
    {
        id: '3',
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        type: 'DEPOSIT',

        incomingAssetId: '2',
        incomingAssetName: 'Euro',

        outgoingAssetId: null,
        outgoingAssetName: null,

        tradeId: null,
        tradeName: null,
        tradeSide: null,

        notes: 'Deposited Euros notes',

        incomingAssetAmount: 400,
        incomingAssetAmountInDefaultCurrency: 453,

        outgoingAssetAmount: null,
        outgoingAssetAmountInDefaultCurrency: null,

        completedAt: '2019-06-24T11:48:07.883' // date time 
    },
    {
        id: '4',
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        type: 'WITHDRAWAL',

        incomingAssetId: null,
        incomingAssetName: null,

        outgoingAssetId: '11',
        outgoingAssetName: 'Swiss Franc',

        tradeId: null,
        tradeName: null,
        tradeSide: null,

        notes: 'Withdrawed Swiss Francs',

        incomingAssetAmount: null,
        incomingAssetAmountInDefaultCurrency: null,

        outgoingAssetAmount: 214,
        outgoingAssetAmountInDefaultCurrency: 245,

        completedAt: '2019-06-24T11:48:07.883' // date time 
    },
    {
        id: '5',
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        type: 'REALIZED_LOSS',

        incomingAssetId: '1',
        incomingAssetName: 'Bitcoin',

        outgoingAssetId: '2',
        outgoingAssetName: 'USDT',

        tradeId: '2',
        tradeName: 'BTCUSDT',
        tradeSide: 'LONG',

        notes: 'Some notes',

        incomingAssetAmount: -0.4673,
        incomingAssetAmountInDefaultCurrency: -13421,

        outgoingAssetAmount: null,
        outgoingAssetAmountInDefaultCurrency: null,

        completedAt: '2019-06-24T11:48:07.883' // date time 
    },
    {
        id: '6',
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        type: 'REALIZED_PROFIT',

        incomingAssetId: '1',
        incomingAssetName: 'Bitcoin',

        outgoingAssetId: '2',
        outgoingAssetName: 'USD',

        tradeId: '2',
        tradeName: 'BTCUSD',
        tradeSide: 'SHORT',

        notes: null,

        incomingAssetAmount: 0.3462,
        incomingAssetAmountInDefaultCurrency: 5637,

        outgoingAssetAmount: null,
        outgoingAssetAmountInDefaultCurrency: null,

        completedAt: '2019-06-24T11:48:07.883' // date time 
    },
    {
        id: '7',
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        type: 'CONVERT',

        incomingAssetId: '1',
        incomingAssetName: 'USDT',

        outgoingAssetId: '2',
        outgoingAssetName: 'Euro',

        tradeId: null,
        tradeName: null,
        tradeSide: null,

        notes: 'Converted Euro for USDT',

        incomingAssetAmount: 400,
        incomingAssetAmountInDefaultCurrency: 453,

        outgoingAssetAmount: 314,
        outgoingAssetAmountInDefaultCurrency: 445,

        completedAt: '2010-08-24T11:48:07.883' // date time 
    },
    {
        id: '8',
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        type: 'DEPOSIT',

        incomingAssetId: '11',
        incomingAssetName: 'Swiss Franc',

        outgoingAssetId: null,
        outgoingAssetName: null,

        tradeId: null,
        tradeName: null,
        tradeSide: null,

        notes: 'Deposited Swiss Francs notes',

        incomingAssetAmount: 400,
        incomingAssetAmountInDefaultCurrency: 453,

        outgoingAssetAmount: null,
        outgoingAssetAmountInDefaultCurrency: null,

        completedAt: '2019-06-24T11:48:07.883' // date time 
    },
];
