import { Broker } from 'app/api/models/broker';



export const exchange: Broker[] = [
    {
        id: '1',
        key: 'FTX',
        name: 'FTX',
        description: 'ftx account - main',
        url: 'http://',
        tradingChartType: 'TRADING_VIEW',
        //
        //
        createdAt: '',
        createdBy: '',
        lastModifiedAt: '',
        lastModifiedBy: ''
    },
    {
        id: '2',
        key: 'default-exchange',
        name: 'default-exchange',
        description: 'default exchange - main',
        url: 'http://',
        tradingChartType: 'TRADING_VIEW'
        //
    },
    {
        id: '3',
        key: 'BINANCE',
        name: 'BINANCE',
        description: 'Binance exchange',
        url: 'http://',
        tradingChartType: 'TRADING_VIEW'
        //
    },
    {
        id: '4',
        key: 'exchange 9',
        name: 'exchange 3',
        description: 'default exchange - main',
        url: 'http://',
        tradingChartType: 'TRADING_VIEW'
        //
    },
    {
        id: '5',
        key: 'exchange 9',
        name: 'exchange 4',
        description: 'default exchange - main',
        url: 'http://',
        tradingChartType: 'TRADING_VIEW'
        //
    },
    {
        id: '6',
        key: 'exchange 9',
        name: 'exchange 5',
        description: 'default exchange - main',
        url: 'http://',
        tradingChartType: 'TRADING_VIEW'
        //
    },
    {
        id: '7',
        key: 'exchange 9',
        name: 'exchange 6',
        description: 'default exchange - main',
        url: 'http://',
        tradingChartType: 'TRADING_VIEW'
        //
    },
    {
        id: '8',
        key: 'exchange 9',
        name: 'exchange 7',
        description: 'default exchange - main',
        url: 'http://',
        tradingChartType: 'TRADING_VIEW'
        //
    },
    {
        id: '9',
        key: 'exchange 9',
        name: 'exchange 8',
        description: 'default exchange - main',
        url: 'http://',
        tradingChartType: 'TRADING_VIEW'
        //
    },
    {
        id: '10',
        key: 'exchange 9',
        name: 'exchange 9',
        description: 'default exchange - main',
        url: 'http://',
        tradingChartType: 'TRADING_VIEW'
        //
    },
    {
        id: '11',
        key: 'exchange 9',
        name: 'exchange 10',
        description: 'default exchange - main',
        url: 'http://',
        tradingChartType: 'TRADING_VIEW'
        //
    }
];
