import { Injectable } from '@angular/core';
import { MockApiBase } from '@fuse/mock-api/mock-api.base';
import { tradingStrategies as tradingStrategiesData } from './data';

@Injectable({
    providedIn: 'root',
})
export class PortfolioTradingStrategyMockApi extends MockApiBase {
    /**
     * Constructor
     */
    constructor() {
        // Register Mock API handlers
        super(
            tradingStrategiesData,
            'v1/portfolio-trading-strategies',
            'v1/portfolio-trading-strategies/{id}'
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
}
