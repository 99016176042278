import { Injectable } from '@angular/core';
import { MockApiBase } from '@fuse/mock-api/mock-api.base';
import { ReportOverviewSummary } from 'app/api/models/report-overview-summary';

@Injectable({
    providedIn: 'root',
})
export class ReportOverviewSummaryMockApi extends MockApiBase {
    /**
     * Constructor
     */
    constructor() {
        //fill data
        const min = 0,
            max = 400;
        const _winCount = Math.floor(Math.random() * (500 - min + 1)) + min;

        const reportOverviewSummary: ReportOverviewSummary = {
            // portfolio: {
            //     id: 'portfolio1',
            //     name: 'portfolio',
            //     currentBalance: 100,
            //     defaultCurrency: 'kn',
            //     riskAllowedPercentage: 3
            // },

            accountBalance: 100300,
            returnAmount: 40300,

            returnWin: 14320,
            returnLoss: 24320,

            returnLong: 14520,
            returnShort: 13420,

            potentialProfits: 5420,
            potentialLoss: 8420,

            winCount: 4,
            lossCount: 6,

            openCount: 11,
            closedCount: 52,

            longCount: 12,
            shortCount: 32,

            profitLossRatio: 1.2,
            profitFactor: 4.2,

            consecutiveWinTradeCount: 5,
            consecutiveLossTradeCount: 3,

            averageWinTradeHoldTime: 124,
            averageLossTradeHoldTime: 255,

            totalFees: 544,
            averageFeesPerTrade: 12,
            averageFeesPerDay: 16,
        };

        // Register Mock API handlers
        super([reportOverviewSummary], 'v1/report/overview-summary', null);
    }
}
