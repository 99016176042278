import { Injectable, inject } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeNorwegian from '@angular/common/locales/nb';
import localeSwedish from '@angular/common/locales/sv';
import localeEnglish from '@angular/common/locales/en';
import localeTurkish from '@angular/common/locales/tr';
import localeCroatian from '@angular/common/locales/hr';
import { DateTimeAdapter } from '@fuse/components/datetime';
// import * as _dateFns from 'date-fns'
// import { es as localeEs, enUS as localeEn } from 'date-fns/locale'

@Injectable({ providedIn: 'root' })
export class SessionService
{

    private _locale: string;
    private _dateAdapter: DateTimeAdapter<any> = inject(DateTimeAdapter);

    get locale(): string
    {
        return this._locale || 'en-US';
    }

    set locale(value: string)
    {
        this._locale = value;
    }

    registerCulture(culture: string): void
    {
        if (!culture)
        {
            return;
        }
        this.locale = culture;

        if (culture.toLowerCase().startsWith('en'))
        {
            this._dateAdapter.setLocale(localeEnglish);
            // _dateFns.setDefaultOptions({ locale: localeEn });
        }

        // Register locale data since only the en-US locale data comes with Angular

        switch (culture)
        {
            case 'en': {
                registerLocaleData(localeEnglish);
                break;
            }
            case 'tr': {
                registerLocaleData(localeTurkish);
                break;
            }
            case 'nb-NO': {
                registerLocaleData(localeNorwegian);
                break;
            }
            case 'sv-SE': {
                registerLocaleData(localeSwedish);
                break;
            }
            case 'hr': {
                registerLocaleData(localeCroatian);
                break;
            }
        }
    }
}
