import { TradingPairReport } from 'app/api/models/trading-pair-report';

const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';

export const tradingPairs: TradingPairReport[] = [
    {
        id: '1',
        tradingPair: {
            id: '2',
            name: 'BTC/USD',
            baseAssetSymbol: 'BTC',
            quoteAssetSymbol: 'USD',
            baseAssetPrecision: 2,
            quoteAssetPrecision: 4,
        },
        //price
        priceCurrentValue: 3,

        //price counter
        priceIncDec30min: 0,
        priceIncDec1h: 0,
        priceIncDec4h: 3,
        priceIncDec8h: 2,
        priceIncDec12h: 1,
        priceIncDec1D: -3,
        priceIncDec3D: -3,
        priceIncDec1W: -4,

        //price change
        pricePercent30min: 0,
        pricePercent1h: 0,
        pricePercent4h: 0.31,
        pricePercent8h: 0.22,
        pricePercent12h: 0.11,
        pricePercent1D: -0.31,
        pricePercent3D: -0.31,
        pricePercent1W: -0.41,

        //rsi
        rsiValue4h: 34,
        rsiValue8h: 23,
        rsiValue12h: 15,
        rsiValue1D: 11,
        rsiValue3D: 42,
        rsiValue1W: 33,

        //rsi change
        rsiPercent4h: 0.12,
        rsiPercent8h: 0.22,
        rsiPercent12h: 0.12,
        rsiPercent1D: -0.32,
        rsiPercent3D: -0.32,
        rsiPercent1W: -0.41,

        //volume value
        volumeValue1D: 22000,
        volumeValue3D: 52000,
        volumeValue1W: 122000,
        // volumeValue1M: 322000,

        //volume change
        volumePercent1D: -0.14,
        volumePercent3D: 0.14,
        volumePercent1W: -0.14,
        // volumePercent1M: 0.14,

        //counts
        brokerCount: 5,
        brokerSpreadPercent: 35,
        //
        favorite: false,
        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55',
    },
    {
        id: '2',
        tradingPair: {
            id: '2',
            name: 'SPELLPERP',
            baseAssetSymbol: 'SPELL',
            quoteAssetSymbol: 'USD',
            baseAssetPrecision: 0,
            quoteAssetPrecision: 7,
        },
        //price
        priceCurrentValue: 3,

        //price counter
        priceIncDec30min: 0,
        priceIncDec1h: 0,
        priceIncDec4h: 3,
        priceIncDec8h: 2,
        priceIncDec12h: 1,
        priceIncDec1D: -3,
        priceIncDec3D: -3,
        priceIncDec1W: -4,

        //price change
        pricePercent30min: 0,
        pricePercent1h: 0,
        pricePercent4h: 0.31,
        pricePercent8h: 0.22,
        pricePercent12h: 0.11,
        pricePercent1D: -0.31,
        pricePercent3D: -0.31,
        pricePercent1W: -0.41,

        //rsi
        rsiValue4h: 34,
        rsiValue8h: 23,
        rsiValue12h: 15,
        rsiValue1D: 11,
        rsiValue3D: 42,
        rsiValue1W: 33,

        //rsi change
        rsiPercent4h: 0.12,
        rsiPercent8h: 0.22,
        rsiPercent12h: 0.12,
        rsiPercent1D: -0.32,
        rsiPercent3D: -0.32,
        rsiPercent1W: -0.41,

        //volume value
        volumeValue1D: 22000,
        volumeValue3D: 52000,
        volumeValue1W: 122000,
        // volumeValue1M: 522000,

        //volume change
        volumePercent1D: -0.14,
        volumePercent3D: 0.14,
        volumePercent1W: -0.14,
        // volumePercent1M: -0.34,

        //counts
        brokerCount: 5,
        brokerSpreadPercent: 35,
        //
        favorite: true,
        //meta
        createdAt: '2023-01-14 11:55',
        lastModifiedAt: '2023-02-14 12:55',
    },
    {
        id: '3',
        tradingPair: {
            id: '2',
            name: 'BNBPERP',
            baseAssetSymbol: 'BNB',
            quoteAssetSymbol: 'USD',
            baseAssetPrecision: 1,
            quoteAssetPrecision: 4,
        },
        //price
        priceCurrentValue: 3,

        //price counter
        priceIncDec30min: 0,
        priceIncDec1h: 0,
        priceIncDec4h: 3,
        priceIncDec8h: 2,
        priceIncDec12h: 1,
        priceIncDec1D: -3,
        priceIncDec3D: -3,
        priceIncDec1W: -4,

        //price change
        pricePercent30min: 0,
        pricePercent1h: 0,
        pricePercent4h: 0.31,
        pricePercent8h: 0.22,
        pricePercent12h: 0.11,
        pricePercent1D: -0.31,
        pricePercent3D: -0.31,
        pricePercent1W: -0.41,

        //rsi
        rsiValue4h: 34,
        rsiValue8h: 23,
        rsiValue12h: 15,
        rsiValue1D: 11,
        rsiValue3D: 42,
        rsiValue1W: 33,

        //rsi change
        rsiPercent4h: 0.12,
        rsiPercent8h: 0.22,
        rsiPercent12h: 0.12,
        rsiPercent1D: -0.32,
        rsiPercent3D: -0.32,
        rsiPercent1W: -0.41,

        //volume value
        volumeValue1D: 22000,
        volumeValue3D: 52000,
        volumeValue1W: 122000,
        // volumeValue1M: 442000,

        //volume change
        volumePercent1D: -0.14,
        volumePercent3D: 0.14,
        volumePercent1W: -0.14,
        // volumePercent1M: -0.34,

        //counts
        brokerCount: 5,
        brokerSpreadPercent: 35,
        //
        favorite: false,
        //meta
        createdAt: '2023-02-14 16:55',
        lastModifiedAt: '2023-04-14 12:55',
    },
    {
        id: '4',
        tradingPair: {
            id: '2',
            name: 'FETUSDT',
            baseAssetSymbol: 'FET',
            quoteAssetSymbol: 'USDT',
            baseAssetPrecision: 1,
            quoteAssetPrecision: 2,
        },
        //price
        priceCurrentValue: 3,

        //price counter
        priceIncDec30min: 0,
        priceIncDec1h: 0,
        priceIncDec4h: 3,
        priceIncDec8h: 2,
        priceIncDec12h: 1,
        priceIncDec1D: -3,
        priceIncDec3D: -3,
        priceIncDec1W: -4,

        //price change
        pricePercent30min: 0,
        pricePercent1h: 0,
        pricePercent4h: 0.31,
        pricePercent8h: 0.22,
        pricePercent12h: 0.11,
        pricePercent1D: -0.31,
        pricePercent3D: -0.31,
        pricePercent1W: -0.41,

        //rsi
        rsiValue4h: 34,
        rsiValue8h: 23,
        rsiValue12h: 15,
        rsiValue1D: 11,
        rsiValue3D: 42,
        rsiValue1W: 33,

        //rsi change
        rsiPercent4h: 0.12,
        rsiPercent8h: 0.22,
        rsiPercent12h: 0.12,
        rsiPercent1D: -0.32,
        rsiPercent3D: -0.32,
        rsiPercent1W: -0.41,

        //volume value
        volumeValue1D: 22000,
        volumeValue3D: 52000,
        volumeValue1W: 122000,
        // volumeValue1M: 422000,

        //volume change
        volumePercent1D: -0.14,
        volumePercent3D: 0.14,
        volumePercent1W: -0.14,
        // volumePercent1M: -0.34,

        //counts
        brokerCount: 5,
        brokerSpreadPercent: 35,
        //
        favorite: false,
        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55',
    },
];
