<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
@if (isScreenSmall) {
<fuse-vertical-navigation
                          class="dark bg-gray-900 print:hidden"
                          [mode]="'over'"
                          [name]="'mainNavigation'"
                          [navigation]="navigation.default"
                          [opened]="false">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center h-20 pt-2 px-6">
      <img
           src="assets/logo/logo.png"
           alt="Trading Metrics">
    </div>
  </ng-container>
</fuse-vertical-navigation>
}

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

  <!-- Header -->
  <div
       class="relative flex flex-0 items-center w-full h-14 sm:h-14 px-1 md:px-4 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden bg-gray-900 dark:bg-gray-900">
    @if (!isScreenSmall) {
    <!-- Logo -->
    <div class="flex items-center mx-2 lg:mr-2">
      <div class="hidden lg:flex">
        <!-- <img
            class="dark:hidden w-24"
            src="assets/images/logo/logo-text.svg"> -->
        <img
             class="h-12"
             src="assets/logo/logo.png">
      </div>
      <img
           class="flex lg:hidden h-12"
           src="assets/logo/logo.png">
    </div>
    <!-- Horizontal navigation -->
    <fuse-horizontal-navigation
                                class="dark bg-gray-900 print:hidden mr-2"
                                [name]="'mainNavigation'"
                                [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
    }
    <!-- Logo -->
    @if (isScreenSmall) {
    <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
    </button>
    <!-- Logo -->
    <div class="flex items-center mx-2 mr-2">
      <div class="flex">
        <img
             class="h-12"
             src="assets/logo/logo.png">
      </div>
    </div>
    }
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <languages></languages>
      <!-- <messages></messages> -->
      <notifications></notifications>
      <user></user>
      <!-- <settings></settings> -->
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
    </div>
    <!-- Navigation toggle button -->
    <!-- <ng-container *ngIf="isScreenSmall">
            <button
              mat-icon-button
              (click)="toggleNavigation('mainNavigation')"
              >
              <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
          </ng-container> -->
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto w-full">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
          Otherwise, layout changes won't be registered and the view won't be updated! -->
    @if (true) {
    <router-outlet></router-outlet>
    }
  </div>

  <!-- Footer -->
  <!-- <div class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-1 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">TradeActa &copy; {{currentYear}}</span>
      </div> -->

</div>