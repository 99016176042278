import { Injectable } from '@angular/core';
import { MockApiBase } from '@fuse/mock-api/mock-api.base';
import { portfolioAssets } from './data';

@Injectable({
    providedIn: 'root',
})
export class PortfolioTradingPairMockApi extends MockApiBase {
    /**
     * Constructor
     */
    constructor() {
        // Register Mock API handlers
        super(
            portfolioAssets,
            'v1/portfolio-trading-pairs',
            'v1/portfolio-trading-pairs/{id}'
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
}
