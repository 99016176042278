import { Injectable } from '@angular/core';
import { MockApiBase } from '@fuse/mock-api/mock-api.base';
import { tradingPatterns as tradingPatternsData } from './data';

@Injectable({
    providedIn: 'root',
})
export class PortfolioTradingPatternTimeFrameMockApi extends MockApiBase {
    /**
     * Constructor
     */
    constructor() {
        // Register Mock API handlers
        super(
            tradingPatternsData,
            'v1/portfolio-trading-pattern-timeframes',
            'v1/portfolio-trading-pattern-timeframes/{id}'
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
}
