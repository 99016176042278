import { Directive, Output, ElementRef, EventEmitter, HostListener, Input, OnInit, OnDestroy, inject } from '@angular/core';

@Directive({
    selector: '[clickOutside]',
    standalone: true
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
	private elementRef = inject(ElementRef);


	@Output() clickOutside: EventEmitter<any>;

	@Input() enableWhiteListing: boolean;

	@Input() whiteListedClasses: string[];


	constructor() {
		this.clickOutside = new EventEmitter<any>();
	}

	ngOnInit(): void {
	}
	ngOnDestroy(): void {
	}


	@HostListener('document:click', ['$event', '$event.target'])
	public onClick(event: Event, targetElement: any): void {
		const isInsideClicked = this.elementRef.nativeElement.contains(event.target);

		/**
		 * If whitelisting is enabled this directive will only
		 *  emit if the clicked element is outside and has a class whitelisted
		 * In all the other cases it will emit if we click outside
		 */
		if (!isInsideClicked) {
			if (this.whiteListedClasses) {
				if (targetElement.classList) {
					const classList = Array.from(targetElement.classList);
					let classIncluded = false;
					for (let i = 0; i < this.whiteListedClasses.length; i++) {
						const element = this.whiteListedClasses[i];
						classIncluded = classList.includes(element);
						if (classIncluded) {
							return;
						}
					}
					this.clickOutside.emit(event);
				}
			} else {
				this.clickOutside.emit(event);
			}
		}
	}

	isDescendant(parent: any, child: any): boolean {
		let node = child;
		while (node !== null) {
			if (node === parent) {
				return true;
			} else {
				node = node.parentNode;
			}
		}
		return false;
	}

}
/**
 *
 *               (clickOutside)="onClickedOutside($event)"
                [whiteListedClasses]="[
                'ng-option',
                'ng-select-container'
                ]"

                    onClickedOutside(e: Event): void {
		//debugger;
        //e.stopPropagation();
		console.log('Clicked outside:', e);
		//this.hide(e);
	}
 */
