import { Injectable } from '@angular/core';
import { MockApiBase } from '@fuse/mock-api/mock-api.base';
import { FilterModel } from 'app/api/data-event-model';
import { trades as tradesData } from './data';

@Injectable({
    providedIn: 'root',
})
export class TradeMockApi extends MockApiBase {
    /**
     * Constructor
     */
    constructor() {
        // Register Mock API handlers
        super(tradesData, 'v1/trades', 'v1/trades/{id}');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getCustomFilterModel(
        key: string,
        value: any,
        _filterModelMap: Map<string, FilterModel>
    ): FilterModel {
        switch (key) {
            case 'portfolioId': {
                return {
                    name: 'portfolio.id',
                    filterSearchType: 'equals',
                    value: value,
                };
            }
            case 'exchangeId': {
                return {
                    name: 'exchange.id',
                    filterSearchType: 'equals',
                    value: value,
                };
            }
        }

        return null;
    }
}
