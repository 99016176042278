import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { UserService } from './api/services/user.service';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver 
{
    private _messagesService = inject(MessagesService);
    private _navigationService = inject(NavigationService);
    private _notificationsService = inject(NotificationsService);
    private _userService = inject(UserService);


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            this._navigationService.get(),
            //TODO enable what is needed
            // this._messagesService.getAll(),
            // this._notificationsService.getAll(),
            // this._shortcutsService.getAll(),
            this._userService.fetchOne()
        ]);
    }
}
