import { Injectable } from '@angular/core';
import { MockApiBase } from '@fuse/mock-api/mock-api.base';
import { assetData } from './data';

@Injectable({
    providedIn: 'root',
})
export class AssetMockApi extends MockApiBase {
    /**
     * Constructor
     */
    constructor() {
        // Register Mock API handlers
        super(assetData, 'v1/assets', 'v1/assets/{id}');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
}
