<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.default"
  [opened]="!isScreenSmall"
  >
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <div class="flex items-center w-full p-4 pl-6">
      <!-- Logo -->
      <div class="flex items-center justify-center">
        <img
          class="w-8"
          src="assets/images/logo/logo.svg"
          >
        </div>
        <!-- Components -->
        <div class="flex items-center ml-auto">
          <notifications></notifications>
          <user [showAvatar]="false"></user>
        </div>
      </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <!-- <ng-container fuseVerticalNavigationContentFooter>
    <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
      <img
        class="max-w-36"
        src="assets/images/logo/logo-text-on-dark.svg">
      </div>
    </ng-container> -->
  </fuse-vertical-navigation>

  <!-- Wrapper -->
  <div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
      class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
      <!-- Navigation toggle button -->
      <button
        mat-icon-button
        (click)="toggleNavigation('mainNavigation')"
        >
        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
      </button>
      <!-- Components -->
      <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
        <languages></languages>
        <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
        <settings></settings>
        <messages></messages>
      </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
      Otherwise, layout changes won't be registered and the view won't be updated! -->
      @if (true) {
        <router-outlet></router-outlet>
      }
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
    <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
  </div>-->

</div>