import { PortfolioTradingStrategy } from 'app/api/models/portfolio-trading-strategy';


export const tradingStrategies: PortfolioTradingStrategy[] = [
    {
        id: '1',
        //portfolio
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        tradeCount: 1,
        efficiencyRatio: 0.24,
        //
        riskMaxPercent: 6,
        paternsMinCount: 2,

        name: 'Support & resistance',
        description: 'Entered trade based on fundemental research',

        entryChecks: [
            { id: '1', name: 'Check divergence on 4h, 12h & D' },
            { id: '2', name: 'Check if RSI is about to bounce' },
            { id: '3', name: 'Check if support' }
        ],
        exitChecks: [
            { id: '1', name: 'Neznam' },
            { id: '2', name: 'Sta' },
            { id: '3', name: 'Treba' }
        ],
    },
    {
        id: '2',
        //portfolio
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        tradeCount: 1,
        efficiencyRatio: 0.24,
        //
        riskMaxPercent: 3,
        paternsMinCount: 2,

        name: 'Full detailed strategy',
        description: 'Entered trade based on fundemental research',

        entryChecks: [],
        exitChecks: []
    },
    {
        id: '3',
        //portfolio
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        tradeCount: 1,
        efficiencyRatio: 0.24,
        //
        riskMaxPercent: 3,
        paternsMinCount: 2,

        name: 'Special trading strategy',
        description: 'Entered trade based on fundemental research',

        entryChecks: [
            { id: '6', name: 'Check code metrics' },
            { id: '7', name: 'Check code metrics. Not sure how long this should be but it is good that it is responsive' }
        ],
        exitChecks: [
            { id: '8', name: 'Testing something' },
            { id: '9', name: 'Not sure what to say here but lets check how long this is so we can check the responsivnes of the UI' },
            { id: '10', name: 'Lets check what will happen' },
            { id: '11', name: 'One more thing to see what will happen, also when its long. So we will see how it goes' },
        ]
    },
];
