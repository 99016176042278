import { Portfolio } from 'app/api/models/portfolio';

export const portfolio: Portfolio[] = [
    {
        id: '0',
        name: 'ftx account',
        description: 'ftx account - main',
        //
        privatePortfolio: true,
        //
        realizedProfitAndLoss: 10.8732,
        potentialProfit: 0.1623,
        potentialLoss: -0.2515,
        tradeCount: 6,
        efficiencyRatio: -100,
        //

        //
        defaultAsset: { id: '1', name: 'USD', symbol: 'USD' },
        //
        userRole: null,
        //status: 'ACTIVE',
        active: true,
        //
        createdAt: '',
        createdBy: '',
        lastModifiedAt: '',
        lastModifiedBy: '',
    },
    {
        id: '1',
        name: 'default-portfolio',
        description: 'default portfolio - main',
        //
        privatePortfolio: false,
        //
        realizedProfitAndLoss: 0.2732,
        potentialProfit: 0.1623,
        potentialLoss: -0.2515,
        tradeCount: 6,
        efficiencyRatio: -42,
        //

        //
        defaultAsset: { id: '1', name: 'USD', symbol: 'USD' },
        //
        userRole: 'OWNER',
        //status: 'ACTIVE',
        active: true,
        //
    },
    {
        id: '3',
        name: 'portfolio 2',
        description: 'default portfolio - main',
        //
        privatePortfolio: true,
        //
        realizedProfitAndLoss: 0,
        potentialProfit: 0.1623,
        potentialLoss: -0.2515,
        tradeCount: 6,
        efficiencyRatio: 0,
        //

        //
        defaultAsset: { id: '1', name: 'USD', symbol: 'USD' },
        //
        userRole: 'MEMBERSHIP_REQUESTED',
        //status: 'ACTIVE',
        active: true,
        //
    },
    {
        id: '4',
        name: 'portfolio 3',
        description: 'default portfolio - main',
        //
        privatePortfolio: true,
        //
        realizedProfitAndLoss: -1.2732,
        potentialProfit: 0.1623,
        potentialLoss: -0.2515,
        tradeCount: 6,
        efficiencyRatio: 22,
        //

        //
        defaultAsset: { id: '1', name: 'USD', symbol: 'USD' },
        //
        userRole: 'READ_ONLY',
        //status: 'ACTIVE',
        active: true,
        //
    },
    {
        id: '5',
        name: 'portfolio 4',
        description: 'default portfolio - main',
        //
        privatePortfolio: true,
        //
        realizedProfitAndLoss: 1.8732,
        potentialProfit: 0.1623,
        potentialLoss: -0.2515,
        tradeCount: 6,
        efficiencyRatio: 35,
        //

        //
        defaultAsset: { id: '1', name: 'USD', symbol: 'USD' },
        //
        userRole: 'TRADER',
        //status: 'ACTIVE',
        active: true,
        //
    },
    {
        id: '6',
        name: 'portfolio 5',
        description: 'default portfolio - main',
        //
        privatePortfolio: true,
        //
        realizedProfitAndLoss: 1.8732,
        potentialProfit: 0.1623,
        potentialLoss: -0.2515,
        tradeCount: 6,
        efficiencyRatio: 54,
        //

        //
        defaultAsset: { id: '1', name: 'USD', symbol: 'USD' },
        //
        userRole: 'MEMBERSHIP_REQUESTED',
        //status: 'ACTIVE',
        active: true,
        //
    },
    {
        id: '7',
        name: 'portfolio 6',
        description: 'default portfolio - main',
        //
        privatePortfolio: true,
        //
        realizedProfitAndLoss: 1.8732,
        potentialProfit: 0.1623,
        potentialLoss: -0.2515,
        tradeCount: 6,
        efficiencyRatio: 99,
        //

        //
        defaultAsset: { id: '1', name: 'USD', symbol: 'USD' },
        //
        userRole: 'READ_ONLY',
        //status: 'ACTIVE',
        active: true,
        //
    },
    {
        id: '8',
        name: 'portfolio 7',
        description: 'default portfolio - main',
        //
        privatePortfolio: true,
        //
        realizedProfitAndLoss: 1.8732,
        potentialProfit: 0.1623,
        potentialLoss: -0.2515,
        tradeCount: 6,
        efficiencyRatio: 100,
        //

        //
        defaultAsset: { id: '1', name: 'USD', symbol: 'USD' },
        //
        userRole: 'OWNER',
        //status: 'ACTIVE',
        active: true,
        //
    },
    {
        id: '9',
        name: 'portfolio 8',
        description: 'default portfolio - main',
        //
        privatePortfolio: true,
        //
        realizedProfitAndLoss: 1.8732,
        potentialProfit: 0.1623,
        potentialLoss: -0.2515,
        tradeCount: 6,
        efficiencyRatio: 65,
        //

        //
        defaultAsset: { id: '1', name: 'USD', symbol: 'USD' },
        //
        userRole: 'MANAGER',
        //status: 'ACTIVE',
        active: true,
        //
    },
    {
        id: '10',
        name: 'portfolio 9',
        description: 'default portfolio - main',
        //
        privatePortfolio: true,
        //
        realizedProfitAndLoss: 1.8732,
        potentialProfit: 0.1623,
        potentialLoss: -0.2515,
        tradeCount: 6,
        efficiencyRatio: -100,
        //

        //
        defaultAsset: { id: '1', name: 'USD', symbol: 'USD' },
        //
        userRole: 'OWNER',
        //status: 'ACTIVE',
        active: true,
        //
    },
    {
        id: '11',
        name: 'portfolio 10',
        description: 'default portfolio - main',
        //
        privatePortfolio: true,
        //
        realizedProfitAndLoss: 1.8732,
        potentialProfit: 0.1623,
        potentialLoss: -0.2515,
        tradeCount: 6,
        efficiencyRatio: -100,
        //

        //
        defaultAsset: { id: '1', name: 'USD', symbol: 'USD' },
        //
        userRole: 'READ_ONLY',
        //status: 'ACTIVE',
        active: true,
        //
    },
];
