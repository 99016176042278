import { EnvironmentProviders, Provider } from '@angular/core';
import { ClickOutsideDirective } from './click-outside.directive';
import { DateAgoPipe } from './date-ago.pipe';
import { LocalDatePipe } from './local-date.pipe';
import { MathAbsPipe } from './math-abs.pipe';


export const provideFrameworkPipes = (): Array<Provider | EnvironmentProviders> =>
{
    return [
        {
            provide: MathAbsPipe,
            useClass: MathAbsPipe
        },
        {
            provide: LocalDatePipe,
            useClass: LocalDatePipe
        },
        {
            provide: DateAgoPipe,
            useClass: DateAgoPipe
        },
        {
            provide: ClickOutsideDirective,
            useClass: ClickOutsideDirective
        },
    ];
};