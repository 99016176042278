import { Trade } from 'app/api/models/trade';

export const trades: Trade[] = [
    {
        //
        portfolioId: '1',
        portfolioName: 'default-portfolio',
        //
        id: '1',
        //
        brokerId: '3',
        brokerName: 'BINANCE',

        portfolioTradingPair: {
            id: '1',
            tradingPair: {
                id: '2',
                name: 'WLDUSDT',
                baseAssetSymbol: 'WLD',
                quoteAssetSymbol: 'USDT',
                baseAssetPrecision: 2,
                quoteAssetPrecision: 4,
            },
            brokerId: '3',
            portfolioId: '1',
            quoteAssetBalanceAmount: 100,
            // TODO add this, for later could be interesting lastPrice: 40000.24,
        },
        //
        tradeSide: 'LONG',
        //trading strategy
        portfolioTradingStrategy: {
            id: '1',
            portfolioId: '1',
            //
            name: 'Full detailed strategy',
            description: '',
            //
            riskMaxPercent: 3,
            paternsMinCount: 2,
            //entry checklist
            entryChecks: [
                { id: '1', name: 'Check divergence on 4h, 12h & D' },
                { id: '2', name: 'Check if RSI is about to bounce' },
                { id: '3', name: 'Check if support' },
            ],
            //exit checklist
            exitChecks: [
                { id: '4', name: 'Check divergence on 4h, 12h & D' },
                { id: '5', name: 'CHeck if resistance is hit' },
                { id: '6', name: 'Check if shooting start is on timeframes' },
            ],
        },
        portfolioTradingStrategyEntryChecks: [
            {
                id: '1',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '2', name: 'Check if RSI is about to bounce', checked: true },
            { id: '3', name: 'Check if support', checked: false },
        ],
        portfolioTradingStrategyExitChecks: [
            {
                id: '4',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '5', name: 'CHeck if resistance is hit', checked: false },
            {
                id: '6',
                name: 'Check if shooting start is on timeframes',
                checked: true,
            },
        ],
        //
        setupPositionAmount: 100,
        avgEntry: 4.3,

        //
        fees: 2.5,
        //
        profit: 6.2,
        profitPercent: 20.0,
        //
        riskRewardRatio: 1.2,
        //
        avgExit: 6.0,
        //
        efficiencyRatio: 0.9,
        //
        openedAt: '2019-06-24T11:48:07.883',
        closedAt: '2018-09-18T11:18:07.883',
        //details
        tradeOrders: [
            {
                id: '1',
                //
                action: 'BUY',
                //
                executedAt: '2023-11-18T05:43',
                amount: 100,
                price: 10.10124,
                fees: 14,
            },
            {
                id: '2',
                //
                action: 'BUY',

                //
                executedAt: '2023-11-18T06:43',
                amount: 50,
                price: 11.1,
                fees: 23,
            },
            {
                id: '3',
                //
                action: 'SELL',

                //
                executedAt: '2023-11-18T08:43',
                amount: 100,
                price: 19.403,
                fees: 12,
            },
            {
                id: '4',
                //
                action: 'SELL',

                //
                executedAt: '2023-11-18T08:43',
                amount: 50,
                price: 20.4,
                fees: 11,
            },
            {
                id: '5',
                //
                action: 'SELL',

                //
                executedAt: '2023-11-18T09:43',
                amount: 50,
                price: 20.4,
                fees: 11,
            },
        ],
        tradeTargets: [
            {
                id: '1',
                //
                price: 15,
                amount: 80,
                //
                profitAmount: 500, //pogledati da li je puni iznos ili samo profit
                profitPercent: 500,
                //
            },
            {
                id: '2',
                //
                price: 18,
                amount: 50,
                //
                profitAmount: 500, //pogledati da li je puni iznos ili samo profit
                profitPercent: 500,
                //
            },
            {
                id: '3',
                //
                price: 20,
                amount: 20,
                //
                profitAmount: 500, //pogledati da li je puni iznos ili samo profit
                profitPercent: 500,
                //
            },
            {
                id: '4',
                //
                price: 20,
                amount: 20,
                //
                profitAmount: 500, //pogledati da li je puni iznos ili samo profit
                profitPercent: 500,
                //
            },
        ],
        tradeStopLosses: [
            {
                id: '1',
                //
                price: 8,
                amount: 80,
                //
                riskAmount: -500,
                riskPercent: -500,
            },
            {
                id: '2',
                //
                price: 5,
                amount: 50,
                //
                riskAmount: -500,
                riskPercent: -500,
            },
            {
                id: '3',
                //
                price: 3,
                amount: 20,
                //
                riskAmount: -500,
                riskPercent: -500,
            },
            {
                id: '4',
                //
                price: 3,
                amount: 20,
                //
                riskAmount: -500,
                riskPercent: -500,
            },
        ],
        //patterns
        setupPatterns: [
            {
                id: '1',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: 'NONE',
                name: 'FUNDEMENTAL',
                description: 'Entered trade based on fundemental research',
                //LONG
                longSetupType: true,
                longRiskType: true,
                longMistakeType: true,
                //SHORT
                shortSetupType: true,
                shortRiskType: true,
                shortMistakeType: true,
            },
            {
                id: '2',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: 'DAY_1',
                name: 'HEIKIN ASHI - BUY',
                description:
                    'HeikinAshi - change from RED to GREEN on monthly chart',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
            {
                id: '3',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: 'DAY_3',
                name: 'HEIKIN ASHI - BUY',
                description:
                    'HeikinAshi - change from RED to GREEN on weakly chart',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
            {
                id: '4',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: 'HOUR_1',
                name: 'HEIKIN ASHI - BUY',
                description:
                    'HeikinAshi - change from RED to GREEN on weakly chart',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        riskPatterns: [
            {
                id: '2',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: 'DAY_1',
                name: ' HEIKIN ASHI - SELL',
                description: 'HeikinAshi - change from GREEN',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        mistakePatterns: [
            {
                id: '3',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: 'MONTH_1',
                name: 'mistake pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        //
        tradeConviction: 'LOW',
        notes: '<p>razlog <strong>ulaska </strong>u trade</p><ul><li>prvi razlog</li><li>drugi</li><li>treci</li></ul><p>Neke dodatne informacije...</p>',
        //
        status: 'OPEN',
    },
    {
        //
        portfolioId: '1',
        portfolioName: 'default-portfolio',
        //
        id: '2',
        //
        brokerId: '1',
        brokerName: 'Binance',
        portfolioTradingPair: {
            id: '1',
            tradingPair: {
                id: '2',
                name: 'SPELLPERP',
                baseAssetSymbol: 'SPELL',
                quoteAssetSymbol: 'USD',
                baseAssetPrecision: 0,
                quoteAssetPrecision: 7,
            },
            brokerId: '1',
            portfolioId: '1',
            portfolioName: 'default-portfolio',
            quoteAssetBalanceAmount: 100000,
        },
        // lastPrice: 40000.24,
        //
        tradeSide: 'LONG',
        //trading strategy
        portfolioTradingStrategy: {
            id: '1',
            portfolioId: '1',
            //
            name: 'Basic strategy',
            description: '',
            //
            riskMaxPercent: 3,
            paternsMinCount: 2,
            //entry checklist
            entryChecks: [
                { id: '1', name: 'Check divergence on 4h, 12h & D' },
                { id: '2', name: 'Check if RSI is about to bounce' },
                { id: '3', name: 'Check if support' },
            ],
            //exit checklist
            exitChecks: [
                { id: '4', name: 'Check divergence on 4h, 12h & D' },
                { id: '5', name: 'CHeck if resistance is hit' },
                { id: '6', name: 'Check if shooting start is on timeframes' },
            ],
        },
        portfolioTradingStrategyEntryChecks: [
            {
                id: '1',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '2', name: 'Check if RSI is about to bounce', checked: true },
            { id: '3', name: 'Check if support', checked: false },
        ],
        portfolioTradingStrategyExitChecks: [
            {
                id: '4',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '5', name: 'CHeck if resistance is hit', checked: false },
            {
                id: '6',
                name: 'Check if shooting start is on timeframes',
                checked: true,
            },
        ],

        //
        setupPositionAmount: 22,
        avgEntry: 24.3,

        //
        fees: 2.5,
        //
        profit: 6.2,
        profitPercent: 35.12,
        //
        riskRewardRatio: 1.2,
        //
        avgExit: 6.0,
        //
        efficiencyRatio: -0.9,
        //
        openedAt: '2019-06-24T11:48:07.883',
        closedAt: '2018-09-18T11:18:07.883',

        tradeOrders: [
            {
                id: '1',
                //
                action: 'BUY',

                //
                executedAt: '2018-09-18T11:18:07.883',
                amount: 15,
                price: 1.3,
                fees: 14,
            },
            {
                id: '3',
                //
                action: 'SELL',

                //
                executedAt: '2019-06-28T11:48:07.883',
                amount: 100,
                price: 19.4,
                fees: 12,
            },
        ],
        //
        tradeTargets: [
            {
                id: '1',
                //
                price: 1.55,
                amount: 15,
                //
                profitAmount: 500, //pogledati da li je puni iznos ili samo profit
                profitPercent: 500,
                //
            },
        ],
        tradeStopLosses: [
            {
                id: '1',
                //
                price: 1.2,
                amount: 15,
                //
                riskAmount: -500,
                riskPercent: -500,
            },
        ],

        setupPatterns: [
            {
                id: '1',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'nesto',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        riskPatterns: [
            {
                id: '2',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'risk pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        mistakePatterns: [
            {
                id: '3',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'mistake pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        //
        tradeConviction: 'LOW',
        notes: 'razlog ulaska u trade',
        //
        status: 'LOSS',
    },
    {
        //
        portfolioId: '1',
        portfolioName: 'default-portfolio',
        //
        id: '3',
        //
        brokerId: '1',
        brokerName: 'Binance',
        portfolioTradingPair: {
            id: '1',
            tradingPair: {
                id: '2',
                name: 'BNBPERP',
                baseAssetSymbol: 'BNB',
                quoteAssetSymbol: 'USD',
                baseAssetPrecision: 2,
                quoteAssetPrecision: 4,
            },
            brokerId: '1',
            portfolioId: '1',
            portfolioName: 'default-portfolio',
            quoteAssetBalanceAmount: 100,
        },
        // lastPrice: 421.24,
        //
        tradeSide: 'SHORT',
        //trading strategy
        portfolioTradingStrategy: {
            id: '1',
            portfolioId: '1',
            //
            name: 'Sample strategy',
            description: '',
            //
            riskMaxPercent: 3,
            paternsMinCount: 2,
            //entry checklist
            entryChecks: [
                { id: '1', name: 'Check divergence on 4h, 12h & D' },
                { id: '2', name: 'Check if RSI is about to bounce' },
                { id: '3', name: 'Check if support' },
            ],
            //exit checklist
            exitChecks: [
                { id: '4', name: 'Check divergence on 4h, 12h & D' },
                { id: '5', name: 'CHeck if resistance is hit' },
                { id: '6', name: 'Check if shooting start is on timeframes' },
            ],
        },
        portfolioTradingStrategyEntryChecks: [
            {
                id: '1',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '2', name: 'Check if RSI is about to bounce', checked: true },
            { id: '3', name: 'Check if support', checked: false },
        ],
        portfolioTradingStrategyExitChecks: [
            {
                id: '4',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '5', name: 'CHeck if resistance is hit', checked: false },
            {
                id: '6',
                name: 'Check if shooting start is on timeframes',
                checked: true,
            },
        ],

        //
        setupPositionAmount: 22,
        avgEntry: 24.3,

        //
        fees: 2.5,
        //
        profit: -6.2,
        profitPercent: -23.14,
        //
        riskRewardRatio: 1.2,
        //
        avgExit: 6.0,
        //
        efficiencyRatio: 0.9,
        //
        openedAt: '2019-06-24T11:48:07.883',
        closedAt: '2018-09-18T11:18:07.883',
        //
        //details
        tradeOrders: [
            {
                id: '1',
                //
                action: 'SELL',

                //
                executedAt: '2018-09-18T11:18:07.883',
                amount: 100,
                price: 5,
                fees: 1,
            },
            {
                id: '2',
                //
                action: 'SELL',

                //
                executedAt: '2018-09-18T11:18:07.883',
                amount: 40,
                price: 4,
                fees: 2,
            },
            {
                id: '3',
                //
                action: 'BUY',

                //
                executedAt: '2019-06-28T11:48:07.883',
                amount: 130,
                price: 3,
                fees: 1,
            },
            {
                id: '4',
                //
                action: 'BUY',

                //
                executedAt: '2019-06-28T11:48:07.883',
                amount: 20,
                price: 2.5,
                fees: 1,
            },
        ],
        //
        tradeTargets: [
            {
                id: '1',
                //
                price: 2,
                amount: 40,
                //
                profitAmount: 500, //pogledati da li je puni iznos ili samo profit
                profitPercent: 500,
                //
            },
            {
                id: '2',
                //
                price: 3,
                amount: 90,
                //
                profitAmount: 500, //pogledati da li je puni iznos ili samo profit
                profitPercent: 500,
                //
            },
            {
                id: '3',
                //
                price: 4,
                amount: 20,
                //
                profitAmount: 500, //pogledati da li je puni iznos ili samo profit
                profitPercent: 500,
                //
            },
        ],
        tradeStopLosses: [
            {
                id: '1',
                //
                price: 6,
                amount: 60,
                //
                riskAmount: -500,
                riskPercent: -500,
            },
            {
                id: '2',
                //
                price: 8,
                amount: 80,
                //
                riskAmount: -500,
                riskPercent: -500,
            },
            {
                id: '3',
                //
                price: 9,
                amount: 40,
                //
                riskAmount: -500,
                riskPercent: -500,
            },
        ],

        //patterns
        setupPatterns: [
            {
                id: '1',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'nesto',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        riskPatterns: [
            {
                id: '2',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'risk pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        mistakePatterns: [
            {
                id: '3',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'mistake pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        //
        tradeConviction: 'HIGH',
        notes: `<p>Provjeriti loading ove komponente, da bude prvo loading a ne da bude krepali sav.. primjer je dok nema neta</p><p><img src="http://google.com/" onerror="alert('Sanatizing not working :(')">Incorrect image src should not open alert</p>`,
        //
        status: 'OPEN',
    },
    {
        //
        portfolioId: '1',
        portfolioName: 'default-portfolio',
        //
        id: '4',
        //
        brokerId: '1',
        brokerName: 'Binance',
        portfolioTradingPair: {
            id: '1',
            tradingPair: {
                id: '2',
                name: 'CELPERP',
                baseAssetSymbol: 'CEL',
                quoteAssetSymbol: 'USD',
                baseAssetPrecision: 5,
                quoteAssetPrecision: 5,
            },
            brokerId: '1',
            portfolioId: '1',
            portfolioName: 'default-portfolio',
            quoteAssetBalanceAmount: 100000,
        },
        // lastPrice: 421.24,
        //
        tradeSide: 'SHORT',
        //trading strategy
        portfolioTradingStrategy: {
            id: '1',
            portfolioId: '1',
            //
            name: 'Short strategy',
            description: '',
            //
            riskMaxPercent: 3,
            paternsMinCount: 2,
            //entry checklist
            entryChecks: [
                { id: '1', name: 'Check divergence on 4h, 12h & D' },
                { id: '2', name: 'Check if RSI is about to bounce' },
                { id: '3', name: 'Check if support' },
            ],
            //exit checklist
            exitChecks: [
                { id: '4', name: 'Check divergence on 4h, 12h & D' },
                { id: '5', name: 'CHeck if resistance is hit' },
                { id: '6', name: 'Check if shooting start is on timeframes' },
            ],
        },
        portfolioTradingStrategyEntryChecks: [
            {
                id: '1',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '2', name: 'Check if RSI is about to bounce', checked: true },
            { id: '3', name: 'Check if support', checked: false },
        ],
        portfolioTradingStrategyExitChecks: [
            {
                id: '4',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '5', name: 'CHeck if resistance is hit', checked: false },
            {
                id: '6',
                name: 'Check if shooting start is on timeframes',
                checked: true,
            },
        ],

        //
        setupPositionAmount: 22,
        avgEntry: 24.3,
        //details
        tradeOrders: [
            {
                id: '1',
                //
                action: 'SELL',

                //
                executedAt: '2018-09-18T11:18:07.883',
                amount: 9523,
                price: 0.42,
                fees: 1,
            },
            {
                id: '2',
                //
                action: 'BUY',

                //
                executedAt: '2018-09-18T11:19:07.883',
                amount: 9523,
                price: 0.34,
                fees: 2,
            },
            {
                id: '3',
                //
                action: 'SELL',

                //
                executedAt: '2018-09-18T11:22:07.883',
                amount: 9523,
                price: 0.34,
                fees: 2,
            },
        ],
        //
        tradeTargets: [
            {
                id: '1',
                //
                price: 0.34,
                amount: 9523,
                //
                profitAmount: 500, //pogledati da li je puni iznos ili samo profit
                profitPercent: 500,
                //
            },
        ],
        tradeStopLosses: [
            {
                id: '1',
                //
                price: 0.44,
                amount: 9523,
                //
                riskAmount: -500,
                riskPercent: -500,
            },
        ],

        //
        fees: 2.5,
        //
        profit: 0,
        profitPercent: 13.54,
        profitNet: 4.22,
        //
        riskRewardRatio: 1.2,
        //
        avgExit: 6.0,
        //
        efficiencyRatio: 0.9,
        //
        openedAt: '2019-06-24T11:48:07.883',
        closedAt: '2018-09-18T11:18:07.883',
        //
        setupPatterns: [
            {
                id: '1',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'nesto',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        riskPatterns: [
            {
                id: '2',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'risk pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        mistakePatterns: [
            {
                id: '3',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'mistake pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        //
        tradeConviction: 'MEDIUM',
        notes: 'razlog ulaska u trade',
        //
        status: 'WIN',
    },
    {
        //
        portfolioId: '1',
        portfolioName: 'default-portfolio',
        //
        id: '5',
        //
        brokerId: '1',
        brokerName: 'Binance',
        portfolioTradingPair: {
            id: '1',
            tradingPair: {
                id: '2',
                name: 'LOOKSPERP',
                baseAssetSymbol: 'BNB',
                quoteAssetSymbol: 'USD',
                baseAssetPrecision: 2,
                quoteAssetPrecision: 4,
            },
            brokerId: '1',
            portfolioId: '1',
            portfolioName: 'default-portfolio',
            quoteAssetBalanceAmount: 100,
        },
        // lastPrice: 421.24,
        //
        tradeSide: 'LONG',
        //trading strategy
        portfolioTradingStrategy: {
            id: '1',
            portfolioId: '1',
            //
            name: 'Long strategy',
            description: '',
            //
            riskMaxPercent: 3,
            paternsMinCount: 2,
            //entry checklist
            entryChecks: [
                { id: '1', name: 'Check divergence on 4h, 12h & D' },
                { id: '2', name: 'Check if RSI is about to bounce' },
                { id: '3', name: 'Check if support' },
            ],
            //exit checklist
            exitChecks: [
                { id: '4', name: 'Check divergence on 4h, 12h & D' },
                { id: '5', name: 'CHeck if resistance is hit' },
                { id: '6', name: 'Check if shooting start is on timeframes' },
            ],
        },
        portfolioTradingStrategyEntryChecks: [
            {
                id: '1',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '2', name: 'Check if RSI is about to bounce', checked: true },
            { id: '3', name: 'Check if support', checked: false },
        ],
        portfolioTradingStrategyExitChecks: [
            {
                id: '4',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '5', name: 'CHeck if resistance is hit', checked: false },
            {
                id: '6',
                name: 'Check if shooting start is on timeframes',
                checked: true,
            },
        ],

        //
        setupPositionAmount: 22,
        avgEntry: 24.3,
        //details
        tradeOrders: [
            {
                id: '1',
                //
                action: 'BUY',

                //
                executedAt: '2018-09-18T11:18:07.883',
                amount: 9523,
                price: 0.42,
                fees: 1,
            },
            {
                id: '2',
                //
                action: 'SELL',

                //
                executedAt: '2018-09-18T11:19:07.883',
                amount: 9523,
                price: 0.55,
                fees: 2,
            },
            {
                id: '3',
                //
                action: 'BUY',

                //
                executedAt: '2018-09-18T11:22:07.883',
                amount: 9523,
                price: 0.34,
                fees: 2,
            },
        ],
        //
        tradeTargets: [
            {
                id: '1',
                //
                price: 0.55,
                amount: 9523,
                //
                profitAmount: 500, //pogledati da li je puni iznos ili samo profit
                profitPercent: 500,
                //
            },
        ],
        tradeStopLosses: [
            {
                id: '1',
                //
                price: 0.37,
                amount: 9523,
                //
                riskAmount: -500,
                riskPercent: -500,
            },
        ],
        //
        fees: 2.5,
        //
        profit: 0,
        profitPercent: 13.54,
        profitNet: 4.22,
        //
        riskRewardRatio: 1.2,
        //
        avgExit: 6.0,
        //
        efficiencyRatio: 0.9,
        //
        openedAt: '2019-06-24T11:48:07.883',
        closedAt: '2018-09-18T11:18:07.883',
        //
        setupPatterns: [
            {
                id: '1',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'nesto',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        riskPatterns: [
            {
                id: '2',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'risk pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        mistakePatterns: [
            {
                id: '3',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'mistake pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        //
        tradeConviction: 'MEDIUM',
        notes: 'razlog ulaska u trade',
        //
        status: 'WIN',
    },
    {
        //
        portfolioId: '1',
        portfolioName: 'default-portfolio',
        //
        id: '6',
        //
        brokerId: '1',
        brokerName: 'Binance',
        portfolioTradingPair: {
            id: '1',
            tradingPair: {
                id: '2',
                name: 'BNBPERP',
                baseAssetSymbol: 'BNB',
                quoteAssetSymbol: 'USD',
                baseAssetPrecision: 2,
                quoteAssetPrecision: 4,
            },
            brokerId: '1',
            portfolioId: '1',
            portfolioName: 'default-portfolio',
            quoteAssetBalanceAmount: 100,
        },
        // lastPrice: 421.24,
        //
        tradeSide: 'SHORT',
        //trading strategy
        portfolioTradingStrategy: {
            id: '1',
            portfolioId: '1',
            //
            name: 'Short strategy',
            description: '',
            //
            riskMaxPercent: 3,
            paternsMinCount: 2,
            //entry checklist
            entryChecks: [
                { id: '1', name: 'Check divergence on 4h, 12h & D' },
                { id: '2', name: 'Check if RSI is about to bounce' },
                { id: '3', name: 'Check if support' },
            ],
            //exit checklist
            exitChecks: [
                { id: '4', name: 'Check divergence on 4h, 12h & D' },
                { id: '5', name: 'CHeck if resistance is hit' },
                { id: '6', name: 'Check if shooting start is on timeframes' },
            ],
        },
        portfolioTradingStrategyEntryChecks: [
            {
                id: '1',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '2', name: 'Check if RSI is about to bounce', checked: true },
            { id: '3', name: 'Check if support', checked: false },
        ],
        portfolioTradingStrategyExitChecks: [
            {
                id: '4',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '5', name: 'CHeck if resistance is hit', checked: false },
            {
                id: '6',
                name: 'Check if shooting start is on timeframes',
                checked: true,
            },
        ],
        //
        setupPositionAmount: 22,
        avgEntry: 24.3,

        //
        fees: 2.5,
        //
        profit: 0,
        profitPercent: 0,
        profitNet: 0,
        //
        riskRewardRatio: 1.2,
        //
        avgExit: 6.0,
        //
        efficiencyRatio: 0.9,
        //
        openedAt: '2019-06-24T11:48:07.883',
        closedAt: '2018-09-18T11:18:07.883',
        //
        tradeOrders: [],
        tradeTargets: [],
        tradeStopLosses: [],

        setupPatterns: [
            {
                id: '1',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'nesto',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        riskPatterns: [
            {
                id: '2',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'risk pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        mistakePatterns: [
            {
                id: '3',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'mistake pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        //
        tradeConviction: 'MEDIUM',
        notes: '"<p>razlog <strong>ulaska </strong>u trade</p><ul><li>prvi razlog</li><li>drugi</li><li>treci</li></ul><p>Neke dodatne informacije...</p>"',
        //
        status: 'WIN',
    },
    {
        //
        portfolioId: '1',
        portfolioName: 'default-portfolio',
        //
        id: '7',
        //
        brokerId: '1',
        brokerName: 'Binance',
        portfolioTradingPair: {
            id: '1',
            tradingPair: {
                id: '2',
                name: 'BNBPERP',
                baseAssetSymbol: 'BNB',
                quoteAssetSymbol: 'USD',
                baseAssetPrecision: 2,
                quoteAssetPrecision: 4,
            },
            brokerId: '1',
            portfolioId: '1',
            portfolioName: 'default-portfolio',
            quoteAssetBalanceAmount: 100,
        },
        // lastPrice: 421.24,
        //
        tradeSide: 'SHORT',
        //trading strategy
        portfolioTradingStrategy: {
            id: '1',
            portfolioId: '1',
            //
            name: 'Short strategy',
            description: '',
            //
            riskMaxPercent: 3,
            paternsMinCount: 2,
            //entry checklist
            entryChecks: [
                { id: '1', name: 'Check divergence on 4h, 12h & D' },
                { id: '2', name: 'Check if RSI is about to bounce' },
                { id: '3', name: 'Check if support' },
            ],
            //exit checklist
            exitChecks: [
                { id: '4', name: 'Check divergence on 4h, 12h & D' },
                { id: '5', name: 'CHeck if resistance is hit' },
                { id: '6', name: 'Check if shooting start is on timeframes' },
            ],
        },
        portfolioTradingStrategyEntryChecks: [
            {
                id: '1',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '2', name: 'Check if RSI is about to bounce', checked: true },
            { id: '3', name: 'Check if support', checked: false },
        ],
        portfolioTradingStrategyExitChecks: [
            {
                id: '4',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '5', name: 'CHeck if resistance is hit', checked: false },
            {
                id: '6',
                name: 'Check if shooting start is on timeframes',
                checked: true,
            },
        ],
        //
        setupPositionAmount: 22,
        avgEntry: 24.3,

        //
        fees: 2.5,
        //
        profit: 0,
        profitPercent: 13.54,
        profitNet: 4.22,
        //
        riskRewardRatio: 1.2,
        //
        avgExit: 6.0,
        //
        efficiencyRatio: 0.9,
        //
        openedAt: '2019-06-24T11:48:07.883',
        closedAt: '2018-09-18T11:18:07.883',

        tradeOrders: [],
        tradeTargets: [],
        tradeStopLosses: [],

        //
        setupPatterns: [
            {
                id: '1',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'nesto',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        riskPatterns: [
            {
                id: '2',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'risk pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        mistakePatterns: [
            {
                id: '3',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'mistake pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        //
        tradeConviction: 'MEDIUM',
        notes: '"<p>razlog <strong>ulaska </strong>u trade</p><ul><li>prvi razlog</li><li>drugi</li><li>treci</li></ul><p>Neke dodatne informacije...</p>"',
        //
        status: 'WIN',
    },
    {
        //
        portfolioId: '1',
        portfolioName: 'default-portfolio',
        //
        id: '8',
        //
        brokerId: '1',
        brokerName: 'Binance',
        portfolioTradingPair: {
            id: '1',
            tradingPair: {
                id: '2',
                name: 'BNBPERP',
                baseAssetSymbol: 'BNB',
                quoteAssetSymbol: 'USD',
                baseAssetPrecision: 2,
                quoteAssetPrecision: 4,
            },
            brokerId: '1',
            portfolioId: '1',
            portfolioName: 'default-portfolio',
            quoteAssetBalanceAmount: 100,
        },
        // lastPrice: 421.24,
        //
        tradeSide: 'SHORT',
        //trading strategy
        portfolioTradingStrategy: {
            id: '1',
            portfolioId: '1',
            //
            name: 'Short strategy',
            description: '',
            //
            riskMaxPercent: 3,
            paternsMinCount: 2,
            //entry checklist
            entryChecks: [
                { id: '1', name: 'Check divergence on 4h, 12h & D' },
                { id: '2', name: 'Check if RSI is about to bounce' },
                { id: '3', name: 'Check if support' },
            ],
            //exit checklist
            exitChecks: [
                { id: '4', name: 'Check divergence on 4h, 12h & D' },
                { id: '5', name: 'CHeck if resistance is hit' },
                { id: '6', name: 'Check if shooting start is on timeframes' },
            ],
        },
        portfolioTradingStrategyEntryChecks: [
            {
                id: '1',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '2', name: 'Check if RSI is about to bounce', checked: true },
            { id: '3', name: 'Check if support', checked: false },
        ],
        portfolioTradingStrategyExitChecks: [
            {
                id: '4',
                name: 'Check divergence on 4h, 12h & D',
                checked: false,
            },
            { id: '5', name: 'CHeck if resistance is hit', checked: false },
            {
                id: '6',
                name: 'Check if shooting start is on timeframes',
                checked: true,
            },
        ],

        //
        setupPositionAmount: 22,
        avgEntry: 24.3,

        //
        fees: 2.5,
        //
        profit: 0,
        profitPercent: 13.54,
        profitNet: 4.22,
        //
        avgExit: 6.0,
        //
        efficiencyRatio: 0.9,
        //
        openedAt: '2019-06-24T11:48:07.883',
        closedAt: '2018-09-18T11:18:07.883',

        tradeOrders: [],
        tradeTargets: [],
        tradeStopLosses: [],

        //
        setupPatterns: [
            {
                id: '1',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'nesto',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        riskPatterns: [
            {
                id: '2',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'risk pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        mistakePatterns: [
            {
                id: '3',
                portfolioId: '1',
                portfolioName: 'Portfolio 1',
                timeFramesEnabled: false,
                timeFrame: null,
                name: 'mistake pattern',
                description: '',
                //LONG
                longSetupType: true,
                longRiskType: false,
                longMistakeType: false,
                //SHORT
                shortSetupType: false,
                shortRiskType: true,
                shortMistakeType: true,
            },
        ],
        //
        tradeConviction: 'MEDIUM',
        notes: 'razlog ulaska u trade',
        //
        status: 'WIN',
    },
];
