/**
  * Usage: dateString | localDate:'format'
 **/

import { Pipe, PipeTransform, inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { SessionService } from './session.service';

@Pipe({
    name: 'dateAgo',
    pure: true,
    standalone: true
})
export class DateAgoPipe implements PipeTransform {
	private session = inject(SessionService);


	transform(value: any, args?: any): any {
		if (value) {
			// Parse given value to moment duration

			let totalMinutes =value;//moment.duration(value).asMinutes();
			let days = Math.floor(totalMinutes / 450);
			let hours = 0;
			let minutes = 0;
			const dayRem = totalMinutes % 450;
		
			if (dayRem > 0) {
			  hours = Math.floor(dayRem / 60);
			  minutes = Math.floor(dayRem % 60);
			}
		
			return `${days}d ${hours}h ${minutes}m`;
			// const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
			// if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
			// 	return 'Just now';
			// const intervals = {
			// 	'year': 31536000,
			// 	'month': 2592000,
			// 	'week': 604800,
			// 	'day': 86400,
			// 	'hour': 3600,
			// 	'minute': 60,
			// 	'second': 1
			// };
			// let counter;
			// for (const i in intervals) {
			// 	counter = Math.floor(seconds / intervals[i]);
			// 	if (counter > 0)
			// 		if (counter === 1) {
			// 			return counter + ' ' + i + ' ago'; // singular (1 day ago)
			// 		} else {
			// 			return counter + ' ' + i + 's ago'; // plural (2 days ago)
			// 		}
			// }
		}
		return value;
	}
}
