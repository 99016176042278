import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { inject } from '@angular/core';
import { FuseMockApiUtils } from '@fuse/mock-api';
import { FuseMockApiService } from '@fuse/mock-api/mock-api.service';
import { FuseUtilsService } from '@fuse/services/utils';
import { Pagination } from 'app/api/base-model';
import { FilterModel } from 'app/api/data-event-model';
import { cloneDeep } from 'lodash-es';

export abstract class MockApiBase {
    protected _data: any[];

    _fuseMockApiService = inject(FuseMockApiService);
    _utilsService = inject(FuseUtilsService);

    /**
     * Constructor
     */
    constructor(data: any[], pathFull: string, pathOne: string) {
        this._data = data;
        // Register Mock API handlers
        this.registerGetAllHandler(
            pathFull,
            !this._utilsService.isNullOrUndefined(pathOne)
        ); //'v1/trades'
        if (pathOne) {
            this.registerDeleteHandler(pathOne); //'v1/trades/{id}'
            this.registerGetOneHandler(pathOne); //'v1/trades/{id}'
            this.registerPostHandler(pathFull); //'v1/trades'
            this.registerPatchHandler(pathOne); //'v1/trades'
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    getCustomFilterModel(
        key: string,
        value: any,
        _filterModelMap: Map<string, FilterModel>
    ): FilterModel {
        return null;
    }

    /**
     * Register Mock API handlers
     */
    registerGetAllHandler(path: string, _fullFeatureHandler: boolean): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Categories - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onGet(path, 2000).reply(({ request }) => {
            // Get available queries
            // const paramsKeys = cloneDeep(request.params.keys());
            const filterParams = request.params.keys().filter(
                (
                    item //
                ) => item !== 'page' && item !== 'size' && item !== 'sort'
            );

            let sortCol = null;
            let sortDir = null;
            if (request.params.has('sort')) {
                const sort = decodeURIComponent(
                    request.params.get('sort')
                ).split(',');
                sortCol = sort[0];
                sortDir = sort[1];
            } else {
                sortCol = 'id';
                sortDir = 'asc';
            }
            const page = parseInt(request.params.get('page') ?? '1');
            const size = parseInt(request.params.get('size') ?? '0');

            // Clone the products
            let items: any[] | null = cloneDeep(this._data);

            // Sort the products
            if (sortCol && sortDir) {
                items.sort((a, b) => {
                    const fieldA = a[sortCol]?.toString().toUpperCase();
                    const fieldB = b[sortCol]?.toString().toUpperCase();
                    return sortDir === 'asc'
                        ? fieldA.localeCompare(fieldB)
                        : fieldB.localeCompare(fieldA);
                });
            }

            const _filterModelMap: Map<string, FilterModel> = new Map<
                string,
                FilterModel
            >();
            for (const key of filterParams) {
                let _value = request.params.get(key);
                let _modifiedValue =
                    _value === 'true' || _value === 'false'
                        ? coerceBooleanProperty(_value)
                        : _value;

                const _customFilterModel = this.getCustomFilterModel(
                    key,
                    _modifiedValue,
                    _filterModelMap
                );
                if (_customFilterModel != null) {
                    _filterModelMap.set(
                        _customFilterModel.name,
                        _customFilterModel
                    );
                } else {
                    const filterModel = this._getFilterModel(
                        key,
                        _modifiedValue,
                        _filterModelMap
                    );
                    _filterModelMap.set(filterModel.name, filterModel);
                }
            }

            _filterModelMap.forEach((_filterModelValue, _filterModelKey) => {
                items = items.filter((item: any) => {
                    const itemPath = _filterModelValue.name.split('.');
                    const itemValue = this._getItemValue(item, itemPath);
                    if (!this._utilsService.isNullOrUndefined(itemValue)) {
                        switch (_filterModelValue.filterSearchType) {
                            case 'contains': {
                                return itemValue
                                    .toLowerCase()
                                    .includes(
                                        _filterModelValue.value.toLowerCase()
                                    );
                            }
                            case 'equals': {
                                return itemValue === _filterModelValue.value;
                            }
                            case 'range': {
                                //TODO add for date time special query
                                return (
                                    itemValue >= _filterModelValue.valueFrom &&
                                    itemValue <= _filterModelValue.valueTo
                                );
                            }
                        }
                    } else {
                        return true;
                    }
                });
            });

            if (!_fullFeatureHandler) {
                // Return the response
                return [200, items[0]];
            }
            // Paginate - Start
            const _totalNumberOfObjects = items.length;
            // Calculate pagination details
            const begin = page * size;
            const end =
                size == 0
                    ? -1
                    : Math.min(size * (page + 1), _totalNumberOfObjects);
            const lastPage =
                size == 0
                    ? -1
                    : Math.max(Math.ceil(_totalNumberOfObjects / size), 1);

            // Prepare the pagination object
            let pagination: Pagination = {};

            // If the requested page number is bigger than
            // the last possible page number, return null for
            // products but also send the last possible page so
            // the app can navigate to there
            if (size == 0) {
                // Prepare the pagination mock-api
                pagination = {
                    page: page,
                    size: size,
                    totalNumberOfObjects: _totalNumberOfObjects,
                    totalNumberOfPages: lastPage,
                };
            } else if (page > lastPage) {
                items = null;
                pagination = {
                    totalNumberOfPages: lastPage,
                };
            } else {
                // Paginate the results by size
                items = items.slice(begin, end);

                // Prepare the pagination mock-api
                pagination = {
                    page: page,
                    size: size,
                    totalNumberOfObjects: _totalNumberOfObjects,
                    totalNumberOfPages: lastPage,
                };
            }

            // Return the response
            return [
                200,
                {
                    data: items,
                    pagination,
                },
            ];
        });
    }

    registerGetOneHandler(path: string): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Product - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onGet(path, 1000).reply(({ request }) => {
            // Get the id from the params
            const urlParts = request.url.split('/');

            const id = urlParts[urlParts.length - 1];

            // Clone the products
            const products = cloneDeep(this._data);

            // Find the product
            const product = products.find((item) => item.id === id);

            // Return the response
            return product != null ? [200, product] : null;
        });
    }

    registerPostHandler(path: string): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Product - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onPost(path, 2000).reply(({ request }) => {
            // Generate a new product
            const item = cloneDeep(request.body.item);
            item.id = FuseMockApiUtils.guid();

            // Unshift the new product
            this._data.unshift(item);

            // Return the response
            return [200, item];
        });
    }

    registerPatchHandler(path: string): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Product - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onPatch(path, 2000).reply(({ request }) => {
            // Get the id and item
            const id = request.body.item.id;
            const _item = cloneDeep(request.body.item);

            // Remove old item
            const _indexOfObject = this._data.findIndex(
                (_tempItem) => _tempItem.id === id
            );
            if (_indexOfObject !== -1) {
                this._data.splice(_indexOfObject, 1);
            }

            // Unshift the new product
            this._data.unshift(_item);

            // Return the response
            return [200, _item];
        });
    }

    registerDeleteHandler(path: string): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Product - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onDelete(path).reply(({ request }) => {
            // Get the id
            const urlParts = request.url.split('/');

            const id = urlParts[urlParts.length - 1];

            // Find the product and delete it
            this._data.forEach((item, index) => {
                if (item.id === id) {
                    this._data.splice(index, 1);
                }
            });

            // Return the response
            return [200, true];
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    private _getFilterModel(
        key: string,
        value: any,
        _filterModelMap: Map<string, FilterModel>
    ): FilterModel {
        if (key.endsWith('Contains')) {
            return {
                name: key.replace('Contains', ''),
                filterSearchType: 'contains',
                value: value,
            };
        } else if (key.endsWith('From')) {
            const _key: string = key.replace('From', '');
            const _filterModel = _filterModelMap.get(_key);
            if (_filterModel) {
                _filterModel.valueFrom = value;
                return _filterModel;
            } else {
                return {
                    name: _key,
                    filterSearchType: 'range',
                    valueFrom: value,
                };
            }
        } else if (key.endsWith('To')) {
            const _key: string = key.replace('To', '');
            const _filterModel = _filterModelMap.get(_key);
            if (_filterModel) {
                _filterModel.valueTo = value;
                return _filterModel;
            } else {
                return {
                    name: _key,
                    filterSearchType: 'range',
                    valueTo: value,
                };
            }
        }

        return {
            name: key,
            filterSearchType: 'equals',
            value: value,
        };
    }

    private _getItemValue(itemTree: any, itemPath: string[]): any {
        const pathLeft = itemPath.shift();
        const tempItemValue = itemTree[pathLeft];
        if (itemPath.length > 0) {
            return this._getItemValue(tempItemValue, itemPath);
        }
        return tempItemValue;
    }

    private _getParamValueType(
        _value: any
    ): 'boolean' | 'text' | 'date' | 'numeric' | 'array' {
        if (typeof _value === 'boolean') {
            return 'boolean';
        }
        if (typeof _value === 'string') {
            return 'text';
        }
        if (typeof _value === 'number') {
            return 'numeric';
        }
        if (Array.isArray(_value)) {
            return 'array';
        }
    }
}
