import { PortfolioMember } from 'app/api/models/portfolio-member';

export const portfolioMembers: PortfolioMember[] = [
    {
        id: '1',
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        userId: '1',
        userName: 'Enzo Gorlomi',
        userEmail: 'enzogorlomi@gmail.com',
        userTwitter: 'cobie',
        userDiscord: '@cobie',
        //
        userRole: 'OWNER',
        //
        tradeCount: 5,
        realizedProfitAndLoss: 1.52,
        //
        createdAt: '',
        createdBy: '',
        lastModifiedAt: '',
        lastModifiedBy: '',
    },
    {
        id: '2',
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        userId: '4',
        userName: 'Antonio Marghereti',
        userEmail: 'Donalt@gmail.com',
        userTwitter: 'donalt',
        userDiscord: 'donaltdisc',
        //
        userRole: 'MANAGER',
        //
        tradeCount: 1,
        realizedProfitAndLoss: 0.32,
        //
        createdAt: '',
        createdBy: '',
        lastModifiedAt: '',
        lastModifiedBy: '',
    },
    {
        id: '3',
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        userId: '3',
        userName: 'Dominick Decocco',
        userEmail: 'IamNomad@gmail.com',
        userTwitter: 'iamnomad',
        userDiscord: '-',
        //
        userRole: 'TRADER',
        //
        tradeCount: 1,
        realizedProfitAndLoss: 0.32,
        //
        createdAt: '',
        createdBy: '',
        lastModifiedAt: '',
        lastModifiedBy: '',
    },
    {
        id: '4',
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        userId: '5',
        userName: 'Pero Djetlic',
        userEmail: 'perodjetlic@gmail.com',
        userTwitter: '-',
        userDiscord: '-',
        //
        userRole: 'READ_ONLY',
        //
        tradeCount: 0,
        realizedProfitAndLoss: 0,
        //
        createdAt: '',
        createdBy: '',
        lastModifiedAt: '',
        lastModifiedBy: '',
    },
    {
        id: '5',
        portfolioId: '1',
        portfolioName: 'Portfolio 1',
        //
        userId: '2',
        userName: 'Cobie',
        userEmail: 'cobie@gmail.com',
        userTwitter: 'cobie',
        userDiscord: '@cobie',
        //
        userRole: 'MEMBERSHIP_REQUESTED',
        //
        tradeCount: 0,
        realizedProfitAndLoss: 0,
        //
        createdAt: '',
        createdBy: '',
        lastModifiedAt: '',
        lastModifiedBy: '',
    },
];
