import { Injectable } from '@angular/core';
import { MockApiBase } from '@fuse/mock-api/mock-api.base';
import { tradingPairs as tradingPairsData } from './data';

@Injectable({
    providedIn: 'root',
})
export class TradingPairMockApi extends MockApiBase {
    /**
     * Constructor
     */
    constructor() {
        // Register Mock API handlers
        super(tradingPairsData, 'v1/trading-pairs', 'v1/trading-pairs/{id}');
    }
}
