import { TradingPairBroker } from 'app/api/models/trading-pair-broker';

const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';

export const tradingPairBrokers: TradingPairBroker[] = [
    {
        id: '1',
        broker: { id: '2', name: 'FTX' },
        tradingPair: {
            id: '4',
            name: 'FETUSDT',
            baseAssetSymbol: 'FET',
            quoteAssetSymbol: 'USDT',
            baseAssetPrecision: 1,
            quoteAssetPrecision: 2,
        },
        //price
        priceCurrentValue: 34.22,
        priceBandPercent: 0.12,

        //volume 1D
        volumeValue1D: 1242124,
        volumePercent1D: 0.12,
        volumeValueLast24h: 0.32,
        //volume 3D
        volumeValue3D: 1242124,
        volumePercent3D: 0.12,
        volumePercentLast24h: 0.32,
        //volume 1W
        volumeValue1W: 1242124,
        volumePercent1W: 0.12,
        // volumeRatio1W: 0.32,
        //volume 1M
        volumeValue1M: 1242124,
        volumePercent1M: 0.12,
        // volumeRatio1M: 0.32,

        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55',
    },
    {
        id: '2',
        broker: { id: '2', name: 'BINANCE' },
        tradingPair: {
            id: '4',
            name: 'FETUSDT',
            baseAssetSymbol: 'FET',
            quoteAssetSymbol: 'USDT',
            baseAssetPrecision: 1,
            quoteAssetPrecision: 2,
        },
        //price
        priceCurrentValue: 34.22,
        priceBandPercent: 0.12,

        //volume 1D
        volumeValue1D: 1242124,
        volumePercent1D: 0.12,
        volumeValueLast24h: 0.32,
        //volume 3D
        volumeValue3D: 1242124,
        volumePercent3D: 0.12,
        volumePercentLast24h: 0.32,
        //volume 1W
        volumeValue1W: 1242124,
        volumePercent1W: 0.12,
        // volumeRatio1W: 0.32,
        //volume 1M
        volumeValue1M: 1242124,
        volumePercent1M: 0.12,
        // volumeRatio1M: 0.32,

        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55',
    },
    {
        id: '3',
        broker: { id: '2', name: 'Huobi' },
        tradingPair: {
            id: '4',
            name: 'FETUSDT',
            baseAssetSymbol: 'FET',
            quoteAssetSymbol: 'USDT',
            baseAssetPrecision: 1,
            quoteAssetPrecision: 2,
        },
        //price
        priceCurrentValue: 34.22,
        priceBandPercent: 0.12,

        //volume 1D
        volumeValue1D: 1242124,
        volumePercent1D: 0.12,
        volumeValueLast24h: 0.32,
        //volume 3D
        volumeValue3D: 1242124,
        volumePercent3D: 0.12,
        volumePercentLast24h: 0.32,
        //volume 1W
        volumeValue1W: 1242124,
        volumePercent1W: 0.12,
        // volumeRatio1W: 0.32,
        //volume 1M
        volumeValue1M: 1242124,
        volumePercent1M: 0.12,
        // volumeRatio1M: 0.32,

        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55',
    },
    {
        id: '4',
        broker: { id: '2', name: 'Coinbase' },
        tradingPair: {
            id: '4',
            name: 'FETUSDT',
            baseAssetSymbol: 'FET',
            quoteAssetSymbol: 'USDT',
            baseAssetPrecision: 1,
            quoteAssetPrecision: 2,
        },
        //price
        priceCurrentValue: 34.22,
        priceBandPercent: 0.12,

        //volume 1D
        volumeValue1D: 1242124,
        volumePercent1D: 0.12,
        volumeValueLast24h: 0.32,
        //volume 3D
        volumeValue3D: 1242124,
        volumePercent3D: 0.12,
        volumePercentLast24h: 0.32,
        //volume 1W
        volumeValue1W: 1242124,
        volumePercent1W: 0.12,
        // volumeRatio1W: 0.32,
        //volume 1M
        volumeValue1M: 1242124,
        volumePercent1M: 0.12,
        // volumeRatio1M: 0.32,

        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55',
    },
    {
        id: '5',
        broker: { id: '2', name: 'KRAKEN' },
        tradingPair: {
            id: '4',
            name: 'FETUSDT',
            baseAssetSymbol: 'FET',
            quoteAssetSymbol: 'USDT',
            baseAssetPrecision: 1,
            quoteAssetPrecision: 2,
        },
        //price
        priceCurrentValue: 34.22,
        priceBandPercent: 0.12,

        //volume 1D
        volumeValue1D: 1242124,
        volumePercent1D: 0.12,
        volumeValueLast24h: 0.32,
        //volume 3D
        volumeValue3D: 1242124,
        volumePercent3D: 0.12,
        volumePercentLast24h: 0.32,
        //volume 1W
        volumeValue1W: 1242124,
        volumePercent1W: 0.12,
        // volumeRatio1W: 0.32,
        //volume 1M
        volumeValue1M: 1242124,
        volumePercent1M: 0.12,
        // volumeRatio1M: 0.32,

        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55',
    },
    {
        id: '6',
        broker: { id: '2', name: 'Broker 6' },
        tradingPair: {
            id: '4',
            name: 'FETUSDT',
            baseAssetSymbol: 'FET',
            quoteAssetSymbol: 'USDT',
            baseAssetPrecision: 1,
            quoteAssetPrecision: 2,
        },
        //price
        priceCurrentValue: 34.22,
        priceBandPercent: 0.12,

        //volume 1D
        volumeValue1D: 1242124,
        volumePercent1D: 0.12,
        volumeValueLast24h: 0.32,
        //volume 3D
        volumeValue3D: 1242124,
        volumePercent3D: 0.12,
        volumePercentLast24h: 0.32,
        //volume 1W
        volumeValue1W: 1242124,
        volumePercent1W: 0.12,
        // volumeRatio1W: 0.32,
        //volume 1M
        volumeValue1M: 1242124,
        volumePercent1M: 0.12,
        // volumeRatio1M: 0.32,

        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55',
    },
    {
        id: '7',
        broker: { id: '2', name: 'Broker 7' },
        tradingPair: {
            id: '4',
            name: 'FETUSDT',
            baseAssetSymbol: 'FET',
            quoteAssetSymbol: 'USDT',
            baseAssetPrecision: 1,
            quoteAssetPrecision: 2,
        },
        //price
        priceCurrentValue: 34.22,
        priceBandPercent: 0.12,

        //volume 1D
        volumeValue1D: 1242124,
        volumePercent1D: 0.12,
        volumeValueLast24h: 0.32,
        //volume 3D
        volumeValue3D: 1242124,
        volumePercent3D: 0.12,
        volumePercentLast24h: 0.32,
        //volume 1W
        volumeValue1W: 1242124,
        volumePercent1W: 0.12,
        // volumeRatio1W: 0.32,
        //volume 1M
        volumeValue1M: 1242124,
        volumePercent1M: 0.12,
        // volumeRatio1M: 0.32,

        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55',
    },
    {
        id: '8',
        broker: { id: '2', name: 'Broker 8' },
        tradingPair: {
            id: '4',
            name: 'FETUSDT',
            baseAssetSymbol: 'FET',
            quoteAssetSymbol: 'USDT',
            baseAssetPrecision: 1,
            quoteAssetPrecision: 2,
        },
        //price
        priceCurrentValue: 34.22,
        priceBandPercent: 0.12,

        //volume 1D
        volumeValue1D: 1242124,
        volumePercent1D: 0.12,
        volumeValueLast24h: 0.32,
        //volume 3D
        volumeValue3D: 1242124,
        volumePercent3D: 0.12,
        volumePercentLast24h: 0.32,
        //volume 1W
        volumeValue1W: 1242124,
        volumePercent1W: 0.12,
        // volumeRatio1W: 0.32,
        //volume 1M
        volumeValue1M: 1242124,
        volumePercent1M: 0.12,
        // volumeRatio1M: 0.32,

        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55',
    },
    {
        id: '9',
        broker: { id: '2', name: 'Broker 9' },
        tradingPair: {
            id: '4',
            name: 'FETUSDT',
            baseAssetSymbol: 'FET',
            quoteAssetSymbol: 'USDT',
            baseAssetPrecision: 1,
            quoteAssetPrecision: 2,
        },
        //price
        priceCurrentValue: 34.22,
        priceBandPercent: 0.12,

        //volume 1D
        volumeValue1D: 1242124,
        volumePercent1D: 0.12,
        volumeValueLast24h: 0.32,
        //volume 3D
        volumeValue3D: 1242124,
        volumePercent3D: 0.12,
        volumePercentLast24h: 0.32,
        //volume 1W
        volumeValue1W: 1242124,
        volumePercent1W: 0.12,
        // volumeRatio1W: 0.32,
        //volume 1M
        volumeValue1M: 1242124,
        volumePercent1M: 0.12,
        // volumeRatio1M: 0.32,

        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55',
    },
    {
        id: '10',
        broker: { id: '2', name: 'Broker 10' },
        tradingPair: {
            id: '4',
            name: 'FETUSDT',
            baseAssetSymbol: 'FET',
            quoteAssetSymbol: 'USDT',
            baseAssetPrecision: 1,
            quoteAssetPrecision: 2,
        },
        //price
        priceCurrentValue: 34.22,
        priceBandPercent: 0.12,

        //volume 1D
        volumeValue1D: 1242124,
        volumePercent1D: 0.12,
        volumeValueLast24h: 0.32,
        //volume 3D
        volumeValue3D: 1242124,
        volumePercent3D: 0.12,
        volumePercentLast24h: 0.32,
        //volume 1W
        volumeValue1W: 1242124,
        volumePercent1W: 0.12,
        // volumeRatio1W: 0.32,
        //volume 1M
        volumeValue1M: 1242124,
        volumePercent1M: 0.12,
        // volumeRatio1M: 0.32,

        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55',
    },
    {
        id: '11',
        broker: { id: '2', name: 'Broker 11' },
        tradingPair: {
            id: '4',
            name: 'FETUSDT',
            baseAssetSymbol: 'FET',
            quoteAssetSymbol: 'USDT',
            baseAssetPrecision: 1,
            quoteAssetPrecision: 2,
        },
        //price
        priceCurrentValue: 34.22,
        priceBandPercent: 0.12,

        //volume 1D
        volumeValue1D: 1242124,
        volumePercent1D: 0.12,
        volumeValueLast24h: 0.32,
        //volume 3D
        volumeValue3D: 1242124,
        volumePercent3D: 0.12,
        volumePercentLast24h: 0.32,
        //volume 1W
        volumeValue1W: 1242124,
        volumePercent1W: 0.12,
        // volumeRatio1W: 0.32,
        //volume 1M
        volumeValue1M: 1242124,
        volumePercent1M: 0.12,
        // volumeRatio1M: 0.32,

        //meta
        createdAt: '2023-04-14 14:55',
        lastModifiedAt: '2023-05-14 14:55',
    },
];
